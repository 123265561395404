import UniversalNav from './header';
import UniversalFooter from './footer';

export default function ShippingPolicy() {
	return (
		<div>
			<UniversalNav />
			<div className="col-md-12 information-entry" >
				<div className="article-container style-1">

					<h2>Shipping/Delivery Term And Condition</h2>

					<p>Effective date: August 31, 2018</p>

					<p>Products: - Estimated Delivery Date/Time will be available during checkout page or order status email within 24 hours. We dispatch order within same business day Tracking Id will provide on email after order confirmation.</p>

					<p>We try hard and make the best of our efforts to deliver your ordered products in excellent condition and in the fastest possible time. All the shipments usually get delivered within 2-3 business days (Except during Natural Calamities, Pandemic) from its shipment date, but may get longer than usual if it is non-metro city. We don’t ship orders on Sunday.
We also keep you notified for the same either by email or SMS notifications. Hence keep checking your email.</p>

					<p>Pre-order Product: - We Don’t Take Any Token Amount for Pre-order Products you have to Pay Full Price of Product One’s Its Released We Will Deliver according To User Selected Delivery Options.</p>

					<p>Services/Delivery Digital Product Timeline – Delivery of services will be confirmed to your registered email ID within 0-24hours.</p>

					<p>Return/Refund/Cancellation:-</p>

					<p>Any Prepaid Game/Gift Card redeemed post-delivery will not be applicable for return/refund under any circumstance</p>

					<p>Once delivering your order there are absolutely no return/refunds. The delivered product (Covered under Manufacturer Warranty) is guaranteed to be exactly as described and in working condition,</p>

					<p>If your delivered product is not as described or not in working condition, email contact. mcubegamesindia@gmail.com within 24 hours of delivery.</p>

					<p>In the case that your delivered product is not as described or not in working condition, a full refund will be issued for the full purchase amount only after post validating necessary information from manufacturer. A reverse pickup will be arranged by us for smooth return process (If applicable).</p>


					<p>Contact Us</p>

					<p>If you have any questions about this Shipping Devliery T&C, please contact us:</p>

					<ul>
						<li>By email: mcubegamesindia@gmail.com</li>
                        <li>Phone - 022 4924 5872</li>
						<li>By visiting this page on our website: https://mcubegames.in/contact</li>
					</ul>

					<p>&nbsp;</p>
				</div>
			</div>
			<UniversalFooter />
		</div>

	);

}