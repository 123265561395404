import React from "react";
import { MDBFooter } from "mdbreact";
import { Container, Row, Col, Image } from "react-bootstrap";
import {
  CaretRightOutlined,
  EnvironmentOutlined,
  EditOutlined,
  PhoneOutlined,
} from "@ant-design/icons";
import logo from "../../assets/img/mg-1.png";
import { Link } from "react-router-dom";
import { BackTop } from "antd";

import SocialIcons from "../utils/social/social_icon";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

function FooterDesktop() {

  return (
    <MDBFooter className="fag-footer">
      <div className="footer-top-area">
        <Container>
          <Row>
            <Col lg={4} md={6} xs={12}>
              <div className="single-footer">
                <h3>About us</h3>
                <p>
                  Mcube Games offers a wide variety of gaming products, including consoles, accessories, headphones, bundles, and the latest releases.Also Mcube Games is a leading name in the gaming industry in INDIA with great after sales service.

                  We work to make your life more enjoyable.
                  Live in your world.Play in ours.
                  We sell only top quality products.
                </p>
                <Link to="/privacy-policy" className="fag-link"> Privacy Policy </Link> <br></br>
                <Link className="fag-link" to="/refund-policy" > Refund Policy </Link> <br></br>
                <Link className="fag-link" to="/shipping-policy" > Shipping/Delivery T & C </Link> <br></br>
                <Link className="fag-link" to="/cancellation-preorder-policy" >Cancellation and Refund Policy for Pre-order </Link> <br></br>
                <Link className="fag-link" to="/cancellation-refund-policy" > Cancellation and Refund Policy for Physical </Link> <br></br>
                <Link className="fag-link" to="/terms-and-condition" > Terms & Conditions </Link>
              </div>
            </Col>


            <Col lg={5} md={6} xs={12}>
              <div className="widget-content">
                <Row>
                  <Col lg={6} md={6} sm={12}>

                  </Col>
                </Row>
              </div>
            </Col>


            <Col lg={3} md={6} xs={12}>
              <div className="single-footer">
                <h3>Contact Us</h3>
                <div className="footer-contact">
                  <h4 className="title">
                    {" "}
                    <EnvironmentOutlined
                      style={{ color: "#ff7a21" }}
                    /> Address{" "}
                  </h4>
                  <p>
                    Shop no.3, Grover Mansion, 91 Mint Road, Ballard Estate, Fort, Mumbai - 400001
                  </p>
                </div>
                <div className="footer-contact">
                  <h4 className="title">
                    {" "}
                    <EditOutlined style={{ color: "#ff7a21" }} /> Email Address
                  </h4>
                  <p>letsplay@mcubegames.in</p>
                  <p>mcubegamesindia@gmail.com</p>
                </div>
                <div className="footer-contact">
                  <h4 className="title">
                    {" "}
                    <PhoneOutlined style={{ color: "#ff7a21" }} /> Phone{" "}
                  </h4>
                  <p>022 4924 5872</p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="footer-bottom">
        <Container>
          <Row>
            <Col lg={12} md={12} xs={12}>
              <div className="footer-bottom-inn">
                <div className="footer-logo">
                  <img
                    alt=""
                    src={logo}
                    width="240px"
                    height="160px"
                    className="navbar-brand logo"
                  />{" "}
                </div>
                <SocialIcons />
                <div className="copyright">
                  <p>&copy; Copyrights 2023 McubeGames - All Rights Reserved</p>
                </div>
              </div>
            </Col>
          </Row>

          <BackTop>
            <div className="mybtn"><i className="fa fa-chevron-up" aria-hidden="true"></i></div>
          </BackTop>

          <a className = "whatsapp-icon"
          href = "https://wa.me/9167695872?text=I'm%20Interested%20Please%20help%20me%20out%20here!"
          target = "_blank" >
            <FontAwesomeIcon icon={faWhatsapp} style={{ fontSize: '30px' }} />
          </a>

        </Container>
      </div>
    </MDBFooter>
  );
}

export default FooterDesktop;
