import React, { useState, useEffect, useCallback } from 'react'
import { Row , Col ,Card , Image, Button} from 'react-bootstrap';
import { useSelector } from 'react-redux';
import API_REQUEST from '../../../config/client';
import Get_image from '../../../config/get_image';
import { Spin } from 'antd';
import { Link } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import Foryou from './foryou';
import { useHistory } from 'react-router-dom';
import HandleBuyNowOnSubmit from '../../utils/mcube_helper/buynow_item';
import Img from 'react-cool-img';
import {
  BrowserRouter,
  Switch,
  useLocation
} from "react-router-dom";


function NewForYou() {

    const { isLoggedIn } = useSelector(state => state.auth);
    const {user} = useSelector(state => state.auth);
    let history = useHistory();
    
    var id = 0;

     if (isLoggedIn) {
        id = user.id;
         if (!id) {
             id = JSON.parse(user).id;
         }
    } else {
         id = 0;
    }
    
    function sleeper(ms) {
        return function(x) {
          return new Promise(resolve => setTimeout(() => resolve(x), ms));
        };
    }
    
    const [fcard,getFcard] = useState(null);
    const [storeData, getStoredData] = useState(false)
    const [displayData, storeDisplayData] = useState(false)


    useEffect(() => {
        var fetchfCard = async () => {
            await API_REQUEST('home', {action:'foryouset','id':'37',"custId":id}).then((response) => getFcard(response));
        // await API_REQUEST('home', {action:'foryouset','id':'37',"custId":id}).then(sleeper(1)).then((response) => getFcard(response));
        }    
        fetchfCard();
    }, [isLoggedIn]);

     const handlebuynow = useCallback((data)=>{
        HandleBuyNowOnSubmit(data)
    },[])

    console.log("fcard ",fcard)

    useEffect(() => {
        if(fcard != null){ 
            let Displayfdata =  fcard.Data.map(item => (
                <div className="games-item mobile" key={item.id}>
                <div className="game-single-item img-contain-isotope">
                    <div className="games-thumb">
                        <div className="games-thumb-image">
                            <Link to={'/product/'+item.Slug}>
                                <Img src={Get_image("products",item.id,item.img_url)} alt="Xbox Game" debounce={1000} />
                                    {/* <Image src={Get_image("products",item.id,item.img_url)} alt="Playstation Game"  /> */}
                            </Link>
                        </div>
                        {/* <div className="game-overlay">
                            <div className="popup-youtube">
                                <p>out of stock</p>
                            </div>
                        </div> */}
                    </div>
                    <div className="games-desc">
                        <h3 className="name-up"><Card.Link href={"/product?id="+item.id}>{item.name}</Card.Link></h3>
                        {/* <p className="game-meta">Action | Desktop</p>
                        <p className="game-meta">Release date:<span> 07.12.2015</span></p>
                        <div className="game-rating">
                            <h4>4.5</h4>
                            <ul>
                                <li><span className="fa fa-star"></span></li>
                                <li><span className="fa fa-star"></span></li>
                                <li><span className="fa fa-star"></span></li>
                                <li><span className="fa fa-star"></span></li>
                                <li><span className="fa fa-star-o"></span></li>
                            </ul>
                        </div> */}
                        <div className="game-action">
                            <div className="game-price">
                                <h4>₹{item.price}</h4>
                                {
                                item.DiscPerc ?  <p className="off">{item.DiscPerc}% Off</p>
                                : <div></div>
                            }
                            </div>
                            <div className="game-buy" id="buy-now-up">
                                {item.PreOrder === "0" ?
                                    <Card.Link  className = 'fag-btn-outline buy-now-btn' id='fag-shop-buy-btn' onClick={data => handlebuynow({id: item.id, isLoggedIn: isLoggedIn, customer_id: id, qty:1 })} href = {"/checkout?src="+item.id}>
                                            Buy Now
                                    </Card.Link>:
                                    <Card.Link  className = 'fag-btn-outline buy-now-btn' id='fag-shop-buy-btn' onClick={data => handlebuynow({id: item.id, isLoggedIn: isLoggedIn, customer_id: id, qty:1 })} href = {"/checkout?src="+item.id}>
                                            Pre Order
                                    </Card.Link>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            ));
            storeDisplayData(Displayfdata)
            getStoredData(true)
        }
    }, [fcard]);

    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

    const LoadForYouSection = () => (
        <div>
            <Col lg={12} className="preorder-col-12">
                
                <div className="site-heading site-head-mobile">
                    <h2 className="heading_animation">For <span>You</span></h2>
                </div>
            </Col>
            <Col lg={12} className="col-12">
                <div className="games-masonary">
                    <div className="clearfix gamesContainer">
                        <Row xs={2} md={3} lg={6} className="g-6-up">
                            {
                                storeData  ? displayData : <Spin indicator={antIcon} />
                            }
                        </Row>
                    </div>
                </div>
            </Col>
        </div>
        
    );

    console.log("storeData ",storeData)
    console.log("fcard ",fcard)
    console.log(storeData && isLoggedIn)
    console.log("Displayfdata ",displayData)
    
    
    return (
        <div>
            {   
                (storeData && isLoggedIn) ? <LoadForYouSection />: <Foryou /> 
            }
        </div>
    )
}

export default NewForYou;
