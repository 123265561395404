import { React, useCallback } from 'react';
import { ShoppingCartOutlined } from '@ant-design/icons';
import Card from "react-bootstrap/Card";
import { Rate } from 'antd';
import { useSelector } from 'react-redux';
import {HandleCartOnSubmit, OpenNotification} from '../utils/mcube_helper/purchase_item';
import Get_image from '../../config/get_image';


const Cardi = (props) => {
    const { key , Name , ProdImg , desc ,prodId,offerprice, price , DiscountPerc ,RatingAvg, Quantity,CategoryName} = props;
    const {
      isLoggedIn
   } = useSelector(state => state.auth);
   const { user } = useSelector(state => state.auth);
   if (isLoggedIn) {
      var customerId = user.id;
      if (!customerId) {
         customerId = JSON.parse(user).id;
      }
   } else {
      customerId = null;
   }

    const handlecart = useCallback((data) => {
        HandleCartOnSubmit(data)
        OpenNotification(Name)
    }, [Name])

    var request = {id: prodId, isLoggedIn: isLoggedIn, customer_id: customerId}

    return (
        //  <div className="fag-games-item mobile">
        //     <div className="games-single-item img-contain-isotope">
            <Card className="fag-card">
                <div className="games-thumb">
                    {/* <div className="games-thumb-image"  style={{ width: '300px', marginLeft: '8px' }}> */}
                        <Card.Img className="fag-img" variant="top" src={Get_image("products",prodId,ProdImg)} />
                    {/* </div>     */}
                </div>    
                <Card.Body className="fag-card-body">
                    <div className = "fag-rating">
                        <Rate disabled='false' allowHalf defaultValue={RatingAvg} style={{ color: '#ffa11a' }} /> 
                    </div>
                    <Card.Link  className="fag-card-name-link" href = "#" > {Name} </Card.Link>
                    <p>{CategoryName} 
                    <ShoppingCartOutlined id="fag-card-cart" className="cart-mobileview" onClick={data => handlecart(request)} style={{ fontSize: '20px', color: '#fff', float: 'right' }} /></p>
                    
                    <div className = "row">
                      <div className = "col-md-4">
                        <p className="fag-card-price">{(offerprice > 0 )? offerprice : price} ₹  </p>
                        {
                            DiscountPerc ? <p className="fag-card-price">{DiscountPerc + '% Off'}</p>
                            : <div></div>
                        }
                      </div>  
                      <div className = "col-md-8" id="fag-btn-div" >
                        <button className='fag-btn' variant="primary">Buy!!</button>
                      </div>  
                    </div>
                </Card.Body>
            </Card>
    //     </div>
    // </div>
    );
}

export default Cardi;