import React , {useEffect, useState} from "react";
import { Image , Card } from 'react-bootstrap';
import { useSelector } from "react-redux";
import 'bootstrap-css-only/css/bootstrap.min.css';
import '../../../assets/css/style.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import API_REQUEST from "../../../config/client";

import QuantityButton from "../../utils/quantity/qty_btn";
import { Redirect } from "react-router";
import Get_image from '../../../config/get_image'


const Items = (props) => {
    const { id , pid , image , name , price , subtotal , quantity , itemsInStock,OfferPrice, Slug} = props;
    //console.log(props)
    const [item,remove] = useState(null);
    const {user} = useSelector(state => state.auth);
    const {isLoggedIn} = useSelector(state => state.auth);

    const [counter, getCounter] =useState(quantity)

    const[prodPrice, getPrice] = useState(price)
    
    const link = "/product/" + Slug;
    var localstorage = localStorage.getItem('cartlist')

    var removeItem = async () => {
        if(isLoggedIn){
            var customerId = JSON.parse(user).id;
            await API_REQUEST('cart', {action:'removeItem','key':id,customerId: customerId}).then((response) => remove(response));
        }
        else{
            if(localstorage !== null){
                var localstorageItem = JSON.parse(localStorage.getItem('cartlist'))
                delete localstorageItem[pid]
                localStorage.setItem('cartlist',JSON.stringify(localstorageItem));
                remove(localstorageItem)
            }
            
        }
    }
   
    if(item != null){
        remove(null);
        window.location.href="/cart";
        // <Redirect to="/cart"/>
    }

    const handleParentCounter = (count) =>{
        getCounter(count)
        return props.handlegt(count)
    }

    useEffect(() => {
        var request = { action: 'getItemPriceOnClickCounter', product_id: id, price:price,qty:counter }
        async function fetchSingleProductPrice() {
            await API_REQUEST('cart', request).then((response) => getPrice(response))
        }
        fetchSingleProductPrice()
    }, [counter]);

    return(
    <>
        <tr className="shop-cart-item" >
            <td className="fag-cart-preview">
                <Image src={Get_image("products",pid,image)} alt="hello"/>
            </td>
            <td className="fag-cart-product">
                {/* <p><a href = {link} >{name}</a></p> */}
                <p className="fag-cart-product-name"><Card.Link href = {link}>{name}</Card.Link></p>
            </td>
            <td className="fag-cart-price">
                {  (!isLoggedIn) ? 
                <p>&#8377;{OfferPrice=== "0"  ?  price : OfferPrice}</p> : <p>&#8377;{price}</p>
                }
            </td>
            <td className="fag-cart-quantity">
                <div className="num-block skin-2">
                    <div className="num-in">
                        <QuantityButton handleParentCounter = {handleParentCounter} id = {id} Quantity = {quantity} ItemsInStock = {itemsInStock}/>
                    </div>
                </div>
            </td>
            <td className="fag-cart-total">
                {  (!isLoggedIn) ? 
                <p>&#8377;{OfferPrice=== "0"  ?  price : OfferPrice}</p> : <p>&#8377;{prodPrice}</p>
                }
            </td>
            <td className="fag-cart-close">
                <Card.Link onClick={removeItem}><FontAwesomeIcon icon={faTimes} style={{color : '#fff'}} /></Card.Link>
            </td>
        </tr>
                              
    </>
  );
};

export default Items;