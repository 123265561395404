import React, { useState, useEffect } from 'react';

import { Form } from 'react-bootstrap';
import Topic from "../../ui/topic";
import API_REQUEST from '../../../config/client';
import { useSelector } from "react-redux";
import H5 from "../../ui/h5";
import {
    Table
} from 'react-bootstrap';

function Profile() {

   const [profile, getProfile] = useState(null);
   const [updtProf, updateProfile] = useState("");
   const [updtAdd, updateAddress] = useState("");
   const [walletData, getWalletData] = useState("No Data Found");
   const [walBal, getWalBal] = useState(null);
   const [updtPass, updatePassword] = useState("");
   const [successful, setSuccessful] = useState(false);
   var DisplayWalletHistory = null;
   const [dd, setdd] = useState(null);
   
   const {
      isLoggedIn
   } = useSelector(state => state.auth);
   const { user } = useSelector(state => state.auth);
   if (isLoggedIn) {
      var customerId = user.id;
      if (!customerId) {
         customerId = JSON.parse(user).id;
      }
   } else {
      customerId = null;
   }
   

   const [values, setValues] = useState({ firstname: '', email: '', mobile: '', username: '' });
   const [pass, setPass] = useState({ currPass: '', newPass: '', confPass: '' });
   const [address, getAddress] = useState({ success: 0, status: 401, Data: Array(0), message: "not fetched" });
   const [addValues, setAddValues] = useState({ address1: '', address2: '', city: '', state: '', country: '', pincode: '' });

   //API REQUEST 
   useEffect(() => {
      var fetchProfile = async () => {
         await API_REQUEST('profile', {
            action: 'getProfile',
            customerId: customerId
         }).then((response) => getProfile(response));
      }
      fetchProfile();
   }, [customerId]);

   useEffect(() => {
      if (profile) {
         setValues({
            firstname: profile.Data.data.firstname,
            email: profile.Data.data.email,
            mobile: profile.Data.data.mobile,
            username: profile.Data.data.username,
         })
      }

   }, [profile]);

   

   const handleUpdate = async (e) => {
      e.preventDefault();
      setSuccessful(false);
      var request = {
         action: 'UpdateProfile',
         customerId: customerId,
         'email': values.email,
         'username': values.username,
         'firstname': values.firstname,
         'mobile': values.mobile
      }

      await API_REQUEST('updateProfile', request).then((response) => updateProfile(response));
      setSuccessful(true);
   };
   

   const handleChange = async (e) => {
      e.preventDefault();
      setSuccessful(false);
      var request = {
         action: 'changePassword',
         customerId: customerId,
         'currPass': pass.currPass,
         'newPass': pass.newPass,
         'confPass': pass.confPass
      }

      await API_REQUEST('changePassword', request).then((response) => updatePassword(response));
      setSuccessful(true);
   };
   

   const handleUpdateAddress = async (e) => {
      e.preventDefault();

      var request = {
         action: 'updateAddress',
         'address1': addValues.address1,
         'address2': addValues.address2,
         'city': addValues.city,
         'state': addValues.state,
         'country': addValues.country,
         'pincode': addValues.pincode,
         customerId: customerId,
      }

      await API_REQUEST('address', request).then((response) => updateAddress(response));

   };

   const components = {
      profile: <div className="single-contact-box">
         <Topic name1={'My '} name2={'Profile'} />
         <Form onSubmit={handleUpdate}>
            <div className="row">
               <div className="col-lg-6">
                  <Form.Group controlId="formBasicEmail">
                     <div className="form-row" >
                        <Form.Label>First Name</Form.Label>
                        <Form.Control className='inpText' name="firstname" onChange={e => setValues({ firstname: e.target.value, email: values.email, mobile: values.mobile, username: values.username })} defaultValue={values.firstname} value={values.firstname} />
                     </div>
                  </Form.Group>
               </div>
               <div className="col-lg-6">
                  <Form.Group controlId="formBasicEmail">
                     <div className="form-row" >
                        <Form.Label>UserName</Form.Label>
                        <Form.Control className='inpText' name="username" type="text" onChange={e => setValues({ firstname: values.firstname, email: values.email, mobile: values.mobile, username: e.target.value })} defaultValue={values.username} value={values.username} />
                     </div>
                  </Form.Group>
               </div>
            </div>
            <div className="row">
               <div className="col-lg-6">
                  <Form.Group controlId="formBasicEmail">
                     <div className="form-row" >
                        <Form.Label>Number</Form.Label>
                        <Form.Control className='inpText' type="phone" onChange={e => setValues({ firstname: values.firstname, email: values.email, mobile: e.target.value, username: values.username })} defaultValue={values.mobile} value={values.mobile} />
                     </div>
                  </Form.Group>
               </div>
               <div className="col-lg-6">
                  <Form.Group controlId="formBasicEmail">
                     <div className="form-row" >
                        <Form.Label>Email</Form.Label>
                        <Form.Control className='inpText' type="email" onChange={e => setValues({ firstname: values.firstname, email: e.target.value, mobile: values.mobile, username: values.username })} defaultValue={values.email} value={values.email} />
                     </div>
                  </Form.Group>
               </div>
            </div>
            {/* <div className="row">
                       <Topic name1={'For'} name2={'You'}/> 
                       <div className = "col-lg-4">
                        <Form.Group controlId="formBasicCheckbox">
                           <Form.Check id="type1" type="checkbox" name="type1" value={76} onChange={toggle1} label="Action" />
                        </Form.Group>     
                       </div> 
                        <div className = "col-lg-4">
                        <Form.Group controlId="formBasicCheckbox">
                            <Form.Check id="type2" type="checkbox" name="type2" value={77} onChange={toggle2} label="Adventure" />
                        </Form.Group>     
                       </div> 
                       <div className = "col-lg-4">
                        <Form.Group controlId="formBasicCheckbox">
                           <Form.Check id="type3" type="checkbox" name="type3" value={82} onChange={toggle3} label="Racing" />
                        </Form.Group>     
                       </div>
                     </div>  
                     <div className="row"> 
                      <div className = "col-lg-4">
                        <Form.Group controlId="formBasicCheckbox">
                           <Form.Check id="type4" type="checkbox" name="type4" value={84} onChange={toggle4} label="Fighting" />
                        </Form.Group>     
                      </div> 
                       <div className = "col-lg-4">
                        <Form.Group controlId="formBasicCheckbox">
                           <Form.Check id="type5" type="checkbox" name="type5" value={78} onChange={toggle5} label="Role Playing" />
                        </Form.Group>     
                       </div>   
                       <div className = "col-lg-4">
                        <Form.Group controlId="formBasicCheckbox">
                           <Form.Check id="type6" type="checkbox" name="type6" value={79} onChange={toggle6} label="FPS" />
                        </Form.Group>     
                       </div>
                      </div>     */}
            <div className="row">
               <div className="col-lg-12">
                  <div className="comment-field form-action">
                     <button type="submit" className="fag-btn">Update</button>
                  </div>
               </div>
            </div>
         </Form>

         {updtProf.message && (
            <div className="form-group">
               <br />
               <div className={successful ? "alert alert-success" : "alert alert-danger"} role="alert">
                  {updtProf.message}
               </div>
            </div>
         )}
      </div>,

      address: <div className="single-contact-box">
         <Topic name1={'Address'} name2={' Management'} />
         <Form onSubmit={handleUpdateAddress}>
            <div className="row">
               <div className="col-lg-6">
                  <Form.Group controlId="formBasicEmail">
                     <div className="form-row" >
                        <Form.Label>Address 1</Form.Label>
                        <Form.Control className='inpText' name="address1" onChange={a => setAddValues({ address1: a.target.value, address2: addValues.address2, city: addValues.city, state: addValues.state, country: addValues.country, pincode: addValues.pincode })} defaultValue={addValues.address1} value={addValues.address1} />
                     </div>
                  </Form.Group>
               </div>
               <div className="col-lg-6">
                  <Form.Group controlId="formBasicEmail">
                     <div className="form-row" >
                        <Form.Label>Address 2</Form.Label>
                        <Form.Control className='inpText' name="address2" type="text" onChange={a => setAddValues({ address1: addValues.address1, address2: a.target.value, city: addValues.city, state: addValues.state, country: addValues.country, pincode: addValues.pincode })} defaultValue={addValues.address2} value={addValues.address2} />
                     </div>
                  </Form.Group>
               </div>
            </div>
            <div className="row">
               <div className="col-lg-6">
                  <Form.Group controlId="formBasicEmail">
                     <div className="form-row" >
                        <Form.Label>City</Form.Label>
                        <Form.Control className='inpText' name="city" type="text" onChange={a => setAddValues({ address1: addValues.address1, address2: addValues.address2, city: a.target.value, state: addValues.state, country: addValues.country, pincode: addValues.pincode })} defaultValue={addValues.city} value={addValues.city} />
                     </div>
                  </Form.Group>
               </div>
               <div className="col-lg-6">
                  <Form.Group controlId="formBasicEmail">
                     <div className="form-row" >
                        <Form.Label>State</Form.Label>
                        <Form.Control className='inpText' name="state" type="text" onChange={a => setAddValues({ address1: addValues.address1, address2: addValues.address2, city: addValues.city, state: a.target.value, country: addValues.country, pincode: addValues.pincode })} defaultValue={addValues.state} value={addValues.state} />
                     </div>
                  </Form.Group>
               </div>
            </div>
            <div className="row">
               <div className="col-lg-6">
                  <Form.Group controlId="formBasicEmail">
                     <div className="form-row" >
                        <Form.Label>Country</Form.Label>
                        <Form.Control className='inpText' name="country" type="text" onChange={a => setAddValues({ address1: addValues.address1, address2: addValues.address2, city: addValues.city, state: addValues.state, country: a.target.value, pincode: addValues.pincode })} defaultValue={addValues.country} value={addValues.country} />
                     </div>
                  </Form.Group>
               </div>
               <div className="col-lg-6">
                  <Form.Group controlId="formBasicEmail">
                     <div className="form-row" >
                        <Form.Label>Pin Code</Form.Label>
                        <Form.Control className='inpText' name="pincode" type="text" onChange={a => setAddValues({ address1: addValues.address1, address2: addValues.address2, city: addValues.city, state: addValues.state, country: addValues.country, pincode: a.target.value })} defaultValue={addValues.pincode} value={addValues.pincode} />
                     </div>
                  </Form.Group>
               </div>
            </div>
            <div className="row">
               <div className="col-lg-12">
                  <div className="comment-field form-action">
                     <button type="submit" className="fag-btn">Update</button>
                  </div>
               </div>
            </div>

            {/* {
                           updtAdd.message && (
                          <div className="form-group">
                            <div className={ successful ? "alert alert-success" : "alert alert-danger" } role="alert">
                              {updtProf.message}
                            </div>
                          </div>
                        )} */}

         </Form>
      </div>,
      changePassword: <div className="single-contact-box">
         <Topic name1={'Change'} name2={' Password'} />
         <Form onSubmit={handleChange}>
            <div className="row">
              <div className = "col-lg-2" ></div>
               <div className="col-lg-8">
                  <Form.Group controlId="formBasicEmail">
                     <div className="form-row" >
                        <Form.Label>Current Password</Form.Label>
                        < Form.Control className = 'inpText'
                        name = "currPass"
                        type = "password"
                        autocomplete = "current-password"
                           onChange={e => setPass({ currPass: e.target.value, newPass: pass.newPass, confPass: pass.confPass })} defaultValue={pass.firstname} value={pass.currPass} />
                     </div>
                  </Form.Group>
                  <Form.Group controlId="formBasicEmail">
                     <div className="form-row" >
                        <Form.Label>New Password</Form.Label>
                        < Form.Control className = 'inpText'
                        name = "newPass"
                        type = "password"
                        autocomplete = "new-password"
                           onChange={e => setPass({ currPass: pass.currPass, newPass: e.target.value, confPass: pass.confPass })} defaultValue={pass.newPass} value={pass.newPass} />
                     </div>
                  </Form.Group>
                  <Form.Group controlId="formBasicEmail">
                     <div className="form-row" >
                        <Form.Label>Confirm Password</Form.Label>
                        < Form.Control className = 'inpText'
                        type = "password"
                        name = "confPass"
                           onChange={e => setPass({ currPass: pass.currPass, newPass: pass.newPass, confPass: e.target.value })} defaultValue={pass.confPass} value={pass.confPass} />
                     </div>
                  </Form.Group>
               </div>
                < div className = "col-lg-2" > </div>
                  <div className="col-lg-12">
                     <div className="comment-field form-action">
                        <button type="submit" className="fag-btn">Update</button>
                     </div>
               </div>
            </div>
         </Form>

         {updtPass.message && (
            <div className="form-group">
               <br />
               <div className={successful ? "alert alert-success" : "alert alert-danger"} role="alert">
                  {
                     updtPass.message
                  }
               </div>
            </div>
         )}
      </div>,
      walletBalance:<div className="single-contact-box" >
         <Topic name1={'Wallet'} name2={' Balance'} />
            <div className="row">
              <div className = "col-lg-2" ></div>
               <div className="col-lg-8">
                 {walBal ? <H5 name1={'Total Balance :  '} name2={walBal + "₹"} /> : <H5 name1={'0'} name2={' ₹'} /> }
                  <div className="fag-table-scroll" style={{height: "350px"}}>
                     <Table className="table" style={{color: "#ffffff"}}>
                     <thead  style={{border: "1px solid"}}>
                           <tr>
                              <th>Transaction ID</th>
                              <th>Type</th>
                              <th>Amount</th>
                              <th>Remark</th>
                              <th>Created On</th>
                           </tr>
                     </thead>
                        {
                           dd ? dd : <p>Hello</p>
                        }
                  </Table>
                </div>
               </div>                  
            </div>
      </div>
   };

   const [render, updateRender] = useState("profile");
   const handleMenuClick = menu => {
      //.log(menu.target.value);
      updateRender(menu.target.value);
      if (menu.target.value === "address") {
         var fetchAddress = async () => {
            await API_REQUEST('address', {
               action: 'fetchAddress',
               customerId: customerId
            }).then((response) => getAddress(response));
         }
         fetchAddress();
      } else if (menu.target.value === "walletBalance"){
          var fetchWallet = async () => {
             await API_REQUEST('profile', {
                action: 'getWalletBalance',
                customerId: customerId
             }).then((response) => getWalletData(response));
          }
          fetchWallet();

      }
   };

   if (walletData !== "No Data Found") {
         getWalBal(walletData.Data)
            DisplayWalletHistory = walletData.WalletTxn.map(wallet => (
                     <tbody style={{color: "#fff"}}>
                              <tr className="shop-cart-item"  style={{color: "#fff"}} >
                                 <td className="fag-cart-price">
                                    <p style={{color: "#ffffff"}}>{ wallet.TransactionId }</p>
                                 </td>
                                 <td className="fag-cart-price">
                                    {/* <p><a href = {link} >{name}</a></p> */}
                                    <p> { wallet.Type } </p>
                                 </td>
                                 <td className="fag-cart-price">
                                    <p>{ wallet.Amount }</p>
                                 </td>
                                 <td className="fag-cart-price">
                                    <p>{ wallet.Details }</p>
                                 </td>
                                 <td className="fag-cart-price">
                                    <p>{ wallet.CreatedOn }</p>
                                 </td>
                              </tr>
                     </tbody>
        ));
    }
   
   if (DisplayWalletHistory){
      setdd(DisplayWalletHistory)
      getWalletData("No Data Found")
   }

   useEffect(() => {
      if (address.status === 200) {
         setAddValues({
            address1: address.Data.address1,
            address2: address.Data.address2,
            city: address.Data.city,
            state: address.Data.state,
            country: address.Data.country,
            pincode: address.Data.pincode,
         })
      }
   }, [address]);


   return (
      <>
         <section className="fag-contact-details-area section_100 mt-70">
            <div className="container">
               <div className="row">
                  <div className="col-lg-4">
                     <div className="single-contact-box">
                        <div className="contact-icon">
                           <i className="fa fa-user-circle"></i>
                        </div>
                        <div className="contact-head">
                           <h4>{values.username}</h4>
                        </div>
                        <div className="profile-div">
                           <button type="submit" className="fag-btn" key="1" name="profile" value="profile" onClick={handleMenuClick}> My Profile </button>
                        </div>
                        <div className="profile-div">
                           <button type="submit" className="fag-btn" key="2" name="address" value="address" onClick={handleMenuClick}> My Address  </button>
                        </div>
                        <div className="profile-div">
                           <button type="submit" className="fag-btn" key="3" name="changePassword" value="changePassword" onClick={handleMenuClick}> Change Password </button>
                        </div>
                        <div className="profile-div">
                           <button type="submit" className="fag-btn" key="3" name="walletBalance" value="walletBalance" onClick={handleMenuClick}> Wallet Balance </button>
                        </div>
                     </div>
                  </div>

                  {
                     <div className="col-lg-8"> {components[render]} </div>
                  }
               </div>
            </div>
         </section>

      </>
   );
}

export default Profile;