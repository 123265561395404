
// """ url servers """
  var CONST_URL = 'https://mcubegames.in:8443/mcube_ecomm/mcubefront/api/Database/admin/';

// """ url windows os """
// var CONST_URL = 'http://localhost/mcube_ecomm/mcubefront/api/Database/admin/'

// """ url montery os """
// var CONST_URL = 'http://localhost/~danishkhan/admin_mcube/api/Database/'
  
export default CONST_URL