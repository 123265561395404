import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

// import './assets/css/style.css'

import 'antd/dist/reset.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import 'react-image-gallery/styles/css/image-gallery.css';
import { Provider } from 'react-redux';
import store from './store';

import { BrowserRouter } from 'react-router-dom';

ReactDOM.render(
 <Provider store={store}>
  <BrowserRouter>
    <App/>
  </BrowserRouter>
 </Provider>  ,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
