import React, { useState } from 'react'
import MenuAPI from './menu_api'
import { Layout, Breadcrumb } from 'antd';
import Attribute from '../forms/attribute/attribute'
import CategoryForm from '../forms/category/category_form'
import UpdateCtg from '../forms/category/update_Category'
import UpdateBanner from '../forms/banner/Update_Banner';
import GenreForm from "../forms/genre/genre_form"
import EmailForm from '../forms/email_content/Email_content';
import SmsTemplate from '../forms/sms_content/Sms_content';
import Testimonial from '../forms/testimonials/Testimonial';
import Banners from '../forms/banner/banners';
import DiscountForm from '../forms/discount/Discount_form';
import UpdateCustomer from '../forms/customer/Customer';
import GoogleAnalytics from '../forms/google_analytics/google_analytics';
import Wallet from '../forms/wallet/Wallet';
import Changepassword from '../forms/change_password/Change_password';
import Myprofile from '../forms/my_profile/My_profile';
import Exclusivedeals from '../forms/exclusive_deals/Exclusive_deals';
import Blogs from '../forms/blogs/Blogs';
import Sms_listing from '../forms/sms_content/sms_listing';
import EmailListing from '../forms/email_content/email_listing';
import CategoryListing from '../forms/category/category_listing';
import GenreListing from '../forms/genre/genre_lisitng';
import BlogListing from '../forms/blogs/blogs_listing';
import ExclusiveListing from '../forms/exclusive_deals/exclusive_listing';
import WalletListing from '../forms/wallet/wallet_listing';
import TestimonialListing from '../forms/testimonials/testimonial_listing';
import BannerListing from '../forms/banner/banner_listing';
import MyProfileListing from '../forms/my_profile/myprofile_listing';
import GoogleListing from '../forms/google_analytics/google_listing';
import ChangePswListing from '../forms/change_password/changepsw';
import { BrowserRouter as Router, Route, NavLink, Switch } from "react-router-dom";
import UpdateBlogs from '../forms/blogs/Update_Blogs';
import UpdateEmail from '../forms/email_content/Update_Email';
import Update_Deals from '../forms/exclusive_deals/Update_Deals';
import UpdateGenre from '../forms/genre/Update_Genre';
import UpdateWallet from '../forms/wallet/Update_Wallet';
import UpdateSms from '../forms/sms_content/Update_Sms';
import UpdateTestimonial from '../forms/testimonials/Update_Testimonial';
import UpdateProfile from '../forms/my_profile/Update_Profile';
import Product_form from '../forms/product/Product_form';
import UpdateProduct from '../forms/product/Update_Product';
import Discount_form from '../forms/discount/Discount_form';
// import Discount_listing from '../forms/discount/Discount_listing'
import Update_Discount from '../forms/discount/Update_Discount'
import OrderSummary from '../forms/order/order_summary'
import News_Letter from '../forms/news_letter/News_letter';
import Update_News from '../forms/news_letter/Update_Newsletter';

const { Header, Content, Footer, Sider } = Layout;
function NavCall(page) {
    // CATEGORY FORM 
    if (page === "category") {
        return (<div>
            <CategoryForm />
        </div>
        );

    } else if (page.substr(0, 14) === "updatecategory") {
        return (<div>
            <UpdateCtg ctgId={page.replace('updatecategory', '')} />
        </div>
        );

    // BANNER FORM 
    } else if (page === "banner") {
        return (<div>
            <Banners />
        </div>
        );

    } else if (page.substr(0, 12) === "updatebanner") {
        return (<div>
            <UpdateBanner BanId={page.replace('updatebanner', '')}/>
        </div>
        );

    // BLOGS FORM 
    } else if (page === "blogs") {
        return (<div>
            <Blogs />
        </div>
        );

    } else if (page.substr(0, 11) === "updateblogs") {
        return (<div>
            <UpdateBlogs BlogId={page.replace('updateblogs', '')} />
        </div>
        );
    
    // EMAIL FORM 
    } else if (page === "email") {
        return (<div>
            <EmailForm />
        </div>
        );

    } else if (page.substr(0, 11) === "updateemail") {
        return (<div>
            <UpdateEmail  EmId={page.replace('updateemail', '')}/>
        </div>
        );

    // EXCLUSIVE DEALS FORM     
    } else if (page === "deals") {
        return (<div>
            <Exclusivedeals />
        </div>
        );

    } else if (page.substr(0, 15) === "updateexclusive") {
        return (<div>
            <Update_Deals excId={page.replace('updateexclusive', '')} />
        </div>
        );

    // GENRE FORM 
    } else if (page === "genre") {
        return (<div>
            <GenreForm />
        </div>
        );
        
    } else if (page.substr(0, 11) === "updategenre") {
        return (<div>
            <UpdateGenre genreId={page.replace('updategenre', '')} />
        </div>
        );
    
    // WALLET FORM 
    } else if (page === "wallet") {
        return (<div>
            <Wallet />
        </div>
        );

    } else if(page.substr(0, 12) === "updatewallet") {
        return (<div>
            <UpdateWallet walletId={page.replace('updatewallet', '')} />
        </div>
        );


    } else if (page === "sms") {
        return (<div>
            <SmsTemplate />
        </div>
        );

    } else if (page.substr(0, 9) === "updatesms")  {
        return (<div>
            <UpdateSms smsId={page.replace('updatesms', '')} />
        </div>
        );

    } else if (page === "testimonal") {
        return (<div>
            <Testimonial />
        </div>
        );

    } else if (page.substr(0, 17) === "updatetestimonial") {
        return (<div>
            <UpdateTestimonial testId={page.replace('updatetestimonial', '')} />
        </div>
        );

    } else if (page === "profile") {
        return (<div>
            <Myprofile />
        </div>
        );

    } else if (page.substr(0, 15) === "updatemyprofile") {
        return (<div>
            <UpdateProfile profileId={page.replace('updatemyprofile', '')}/>
        </div>
        );


    } else if (page === "product") {
        return (<div>
            <Product_form/>
        </div>
        );
    }  else if (page.substr(0, 13) === "updateproduct") {
        return (<div>
            <UpdateProduct ProdId={page.replace('updateproduct', '')}/>
        </div>
        );


    } else if (page === "news") {
        return (<div>
            <News_Letter/>
        </div>
        );
    }  else if (page.substr(0, 10) === "updatenews") {
        return (<div>
            <Update_News NewsId={page.replace('updatenews', '')}/>
        </div>
        );



    } else if (page.substr(0, 14) === "updatediscount") {
        return (<div>
            <Update_Discount discountId={page.replace('updatediscount', '')}/>
        </div>
        );

    } else if (page === "discount") {
        return (<div>
            <Discount_form/>
        </div>
        );

    } else if (page.substr(0, 13) === "order_summary") {
        return (<div>
            <OrderSummary orderId={page.replace('order_summary', '')} />
        </div>
        );
    }
}

export default NavCall;