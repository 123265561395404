import React, { useEffect } from 'react';
// import Banner from '../components/header/banner';
// import {MDBIcon } from "mdbreact";
// import '../assets/css/style.css';
// import '../assets/css/responsive.css';
// import '@fortawesome/fontawesome-free/css/all.min.css';
import "bootstrap-css-only/css/bootstrap.min.css";
import UniversalNav from "../components/header/navBar_component";
import { MDBFooter } from "mdbreact";
import { Container, Row, Col } from "react-bootstrap";
import Jwt_Decode from 'jwt-decode';
import { Helmet } from 'react-helmet';
import FooterDesktop from '../components/footer/footer_component';

import {
   CaretRightOutlined,
   EnvironmentOutlined,
   EditOutlined,
   PhoneOutlined,
} from "@ant-design/icons";
import logo from "../assets/img/mg-1.png";
import { Link } from "react-router-dom";
import { BackTop } from "antd";
import SocialIcons from "../components/utils/social/social_icon";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

if (localStorage.getItem("tok")) {
   const jwt_Token_decoded = Jwt_Decode(localStorage.getItem("tok"));
   if (jwt_Token_decoded.data.exp * 1000 < Date.now()) {
      localStorage.clear();
      window.location.href = "/login";
   }
}

function About() {

   // Scroll to top when page renders
   useEffect(() => {
      window.scrollTo(0, 0);
   }, []);


   return (
      <>
         <Helmet>
            <title>About Mcube Games</title>‍
            <meta name="description" conttent="Like all things in life, gaming too continuously keeps on evolving. With this we bring you one of the biggest revolution the Indian gaming space has ever seen, Cube Game, a one-stop shop for the best PS5 games, XBOX, Nintendo Switch and PC Games. Get ready for all new updates about games to be brought to you first through us. Your constant search for something as trivial as socks for your controllers to the release date and availability of the next COD ends here.

What sets us apart from the rest of the internet’s conjecture on gaming? We actually know it!

MCUBE GAMES is a leading name in gaming industry in INDIA with great after sales service.
We work to make your life more enjoyable.
Live in your world. Play in ours.
We sell only top quality products."/>
            <meta name="keywords" content="Playstation, playstation 4,sony playstation 5 stores,ps3, ps5 games,ps plus,ps4 games,playstation plus,playstation 3,ps vita,playstation 2,play station 5,ps 4,playstation 5 console,sony playstation ps5 console,ps4 console,playstation 1,ps4 price,ps5 digital,xbox series x,xbox series s,xbox one,xbox one s,xbox 360,xbox one x,xbox controller,xbox x,xbox one controller,xbox live gold,microsoft xbox series x,xbox one series x,series x,wii u,nintendo wii,wii console,nintendo wii u,wii controller,wii remote,wii u console,nintendo wii console, ps4 controller,ps5 controller,joystick,dualshock 4,gamepad,joystick ps4,ps3 controller,dualsense,steam controller,razer kishi,pc controller,game controller,ps2 controller,backbone controller,ps4 game controllers,ps5 media remote,ps5 remote,ps4 controller on pc,game pad,ps4 remote,sony ps4 controller,dualshock 4 controller,best controller for pc,gaming headset,bluetooth headset,logitech g pro x,best gaming headset,razer blackshark v2,hyperx headset,gaming headphones,logitech g733,razer headset,plantronics headset,headphones with mic,logitech headset,ps4 headset,jabra headset,arctis 7,jabra evolve 65,jabra evolve 75,wireless headset,wired headphones,corsair virtuoso,arctis pro wireless,headset with mic,jbl quantum 100,corsair headset,logitech pro x" />
         </Helmet>
         <UniversalNav />
         {/* <Banner header={"About Us"} /> */}
         <section className="fag-about-area section_200  col-12 col-sm-12 col-md-12 .col-lg-12 .col-xl-12  mt-70">
            <div className="container col-12 col-sm-7 col-md-12 .col-lg-12 .col-xl-12 ">
               <div className="row1 col-12 col-sm-7 col-md-7 .col-lg-12 .col-xl-12">
                  <div className="about-top  ">
                     <p className="left col-md-12 .col-lg-7 .col-xl-7">
                        <p className="about_us col-md-12 .col-lg-7 .col-xl-7">ABOUT US</p>
                        <h2 className="left_1 col-md-12 .col-lg-7 .col-xl-7">Get to know us better</h2>
                        Like all things in life, gaming too continuously keeps on
                        evolving. With this we bring you one of the biggest revolution
                        the Indian gaming space has ever seen, Cube Game, a one-stop
                        shop for the best PS4 games, XBOX, Nintendo Switch and PC Games.
                        Get ready for all new updates about games to be brought to you
                        first through us. Your constant search for something as trivial
                        as socks for your controllers to the release date and
                        availability of the next GTA ends here.
                        <br></br><br></br>
                        What sets us apart from the rest of the internet’s conjecture on gaming? We actually
                        know it!
                        <br></br><br></br>
                        <a href="#">MCUBE GAMES</a> is a leading name in gaming industry in
                        India with great after sales service.
                        <br></br>
                        We work to make your life more enjoyable.
                        <br></br>
                        Live in your world. Play in ours.
                        <br></br>
                        We sell only top quality products.
                     </p>
                  </div>
               </div>
            </div>
            {/* <div class="about-top">
         <h2><span>Interesting</span>Facts</h2>
         <div className="container">
            <div className="row">
               <div className="col-lg-4">
                  <div className="single-contact-box">
                     <div className="contact-icon">
                     <MDBIcon icon="map" />
                     
                     </div>
                     <div className="contact-head">
                        <h4>Location</h4>
                     </div>
                     <div className="contact-text">
                        <p>Shop no. 3, Grover Mansion, 91 Mint Road, Ballard Estate, Fort, Mumbai - 400001</p>
                     </div>
                  </div>
               </div>
               <div className="col-lg-4">
                  <div className="single-contact-box">
                     <div className="contact-icon">
                     <MDBIcon icon="phone" />
                     </div>
                     <div className="contact-head">
                        <h4>Phones</h4>
                     </div>
                     <div className="contact-text">
                        <p>Office: 022 4924 5872</p>
                        <p>Whatsapp: 91-6769-5872</p>
                     </div>
                  </div>
               </div>
               <div className="col-lg-4">
                  <div className="single-contact-box">
                     <div className="contact-icon">
                     <MDBIcon icon="envelope" />
                     </div>
                     <div className="contact-head">
                        <h4>Email</h4>
                     </div>
                     <div className="contact-text">
                        <p>letsplay@mcubegames.in</p>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         </div> */}
         </section>

         <FooterDesktop />

      </>
   );

}
export default About;