import React from "react";
// import Banner from '../components/header/banner';
import UniversalNav from './template/header';
import UniversalFooter from './template/footer';

import ProductDetails from '../components/content-section/single-product/singleProduct_main';

import Jwt_Decode from 'jwt-decode';



// import '../assets/css/style.css';

if (localStorage.getItem("tok")) {
    const jwt_Token_decoded = Jwt_Decode(localStorage.getItem("tok"));
    if (jwt_Token_decoded.data.exp * 1000 < Date.now()) {
        localStorage.clear();
        window.location.href = "/login";
    }
}

function SingleProduct(){
    return(
        <div>
            <UniversalNav />
            {/* <Banner header={'Product Details'}/> */}
            <ProductDetails />
            <UniversalFooter />
            
        </div>
    )
}

export default SingleProduct;