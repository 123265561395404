import React, { useState, useEffect, useCallback } from "react";
import API_REQUEST from "../../../config/client";
import ReactPaginate from "react-paginate";
import { Button } from "antd";
import NavCall from "../../menu/main_header";
import DataTable from "react-data-table-component";

function ProductListing() {
  const [offset, setOffset] = useState(0);
  const [posts, setPosts] = useState(null);
  const [perPage] = useState(10);
  const [pageCount, setPageCount] = useState(0);
  const [startingPage, setStartPage] = useState(0);
  const [sdata, setData] = useState("");
  const [sPage, setSPage] = useState("");
  const [uPage, setUPage] = useState("");
  const [product, setProduct] = useState(null);
  const [displayPage, setDisplayPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(0); // Added state for current page
  const [storedSearchTerm, setStoredSearchTerm] = useState(""); // Added state for storing the search term

  const [goToPageValue, setGoToPageValue] = useState("");

  useEffect(() => {
    async function fetchData() {
      await API_REQUEST("fetch", { action: "getProducts" }).then((response) =>
        setPosts(response)
      );
    }
    fetchData();
  }, [offset]);

  const columns = [
    {
      name: "ProductID",
      selector: (row) => row.ProductID,
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => row.Name,
      sortable: true,
    },
    {
      name: "Price",
      selector: (row) => row.Price,
      sortable: true,
    },
    {
      name: "OfferPrice",
      selector: (row) => row.OfferPrice,
      sortable: true,
    },
    {
      name: "Quantity",
      selector: (row) => row.Quantity,
      sortable: true,
    },
    {
      name: "IsActive",
      selector: (row) => row.IsActive,
      sortable: true,
    },
    {
      name: "Action",
      selector: (row) => (
        <Button value={row.ProductID} onClick={handleUpdate}>
          <i value={row.ProductID} class="fa fa-edit"></i>
        </Button>
      ),
      sortable: true,
    },
  ];

  const handleList = useCallback((e) => {
    e.preventDefault();
    setDisplayPage(1);
    const page = "product";
    setSPage(NavCall(page));
  }, []);

// ...

const handleUpdate = async (e) => {
  e.preventDefault();
  const page = "updateproduct" + e.currentTarget.value;
  setDisplayPage(2);
  setUPage(NavCall(page));
  setCurrentPage(Math.floor(offset / perPage)); // Update current page
  setStoredSearchTerm(searchTerm); // Store search term
};

const handleBack = useCallback((e) => {
  e.preventDefault();
  setDisplayPage(0);
  const currentPageIndex = Math.floor(offset / perPage); // Calculate the current page index
  setStartPage(currentPageIndex)
  const newOffset = currentPageIndex * perPage; // Calculate the new offset based on the current page index
  setOffset(newOffset);
  setCurrentPage(currentPageIndex); // Set the current page based on the calculated index
  setSearchTerm(storedSearchTerm); // Restore the search term
}, [offset, perPage, storedSearchTerm]);


// ...

const handleGoToPage = () => {
  const parsedPage = parseInt(goToPageValue, 10);
  if (parsedPage > 0 && parsedPage <= pageCount) {
    const newOffset = (parsedPage - 1) * perPage;
    setOffset(newOffset);
    setCurrentPage(parsedPage - 1);
    setStartPage(parsedPage - 1);
  }
  setGoToPageValue(""); // Clear the input field after navigation
};



  const handleClick = (e) => {
    setSearchTerm(e.target.value);
    setOffset(0);
  };

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected); // Update the current page
    setOffset(selected * perPage);
  };


  const filteredData = posts?.data.filter((i) => {
    if (searchTerm === "") {
      return i;
    } else if (
      (i.ProductID + i.Name + i.SKU).toLowerCase().includes(
        searchTerm.toLowerCase()
      )
    ) {
      return i;
    }
  });

  useEffect(() => {
    if (filteredData) {
      setPageCount(Math.ceil(filteredData.length / perPage));
    }
  }, [filteredData, perPage]);

  if (displayPage === 0) {
    return (
      posts && (
        <div>
          <div className="button">
            <button
              type="submit"
              onClick={handleList}
              className="btn btn-primary button"
            >
              Add Product
            </button>
          </div>

          <div>
            <input
              type="text"
              value={searchTerm}
              onChange={handleClick}
              placeholder="Search..."
              className="searchbox_admin"
            />
          </div>

          <div>
  <input
    type="number"
    value={goToPageValue}
    onChange={(e) => setGoToPageValue(e.target.value)}
    placeholder="Go to page..."
    className="searchbox_admin"
  />
  <button className = "btn btn-primary button" onClick={handleGoToPage}>Go</button>
</div>

   {/* Add the current page number display */}
          <div
            className="current-page"
            style={{
              padding: "5px",
              marginBottom: "10px",
            }}
          >
            Current Page:{" "}
            <span
              className="current-page-number"
              style={{
                fontWeight: "bold",
              }}
            >
              {currentPage + 1}
            </span>
          </div>


          <DataTable
            columns={columns}
            pagination
            highlightOnHover
            data={filteredData?.slice(offset, offset + perPage)}
          />

          <ReactPaginate
            previousLabel={"<<"}
            nextLabel={">>"}
            breakLabel={". . ."}
            breakClassName={"break-me"}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            initialPage={startingPage}
            onPageChange={handlePageChange}
            containerClassName={"pagination"}
            activeClassName={"active"}
          />
        </div>
      )
    );
  } else if (displayPage === 1) {
    return (
      <>
        <div className="button">
          <button
            style={{ marginRight: 1 + "em", border: "none", color: "white" }}
            type="submit"
            onClick={handleBack}
            className="btn btn-primary button"
          >
            Back
          </button>
        </div>

        {sPage ? sPage : <p>No Data </p>}
      </>
    );
  } else if (displayPage === 2) {
    return (
      <>
        <div className="button">
          <button
            style={{ marginRight: 1 + "em", border: "none", color: "white" }}
            type="submit"
            onClick={handleBack}
            className="btn btn-primary button"
          >
            Back
          </button>
        </div>

        {uPage ? uPage : <p>No Data </p>}
      </>
    );
  }
}

export default ProductListing;
