import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faYoutube,faFacebook,faTwitter,faInstagram, faGooglePlus } from '@fortawesome/free-brands-svg-icons';

function SocialIcons(){
    return (
        <div className="footer-social">
            <ul className="fa-ul">
                <li>
                    <a href="https://www.youtube.com/channel/UC_Y8Csqz531Wly5CAqQk9Jw"
                        className="social" target="_blank" >
                        <FontAwesomeIcon icon={faYoutube} size="1x" />
                    </a>
                    <a href="https://www.facebook.com/McubeG/"
                        className="social" target="_blank" >
                        <FontAwesomeIcon icon={faFacebook} size="1x" />
                    </a>
                    <a href="https://twitter.com/mcubeg?lang=en"
                        className="social" target="_blank" >
                        <FontAwesomeIcon icon={faTwitter} size="1x" />
                    </a>
                    <a href="https://www.instagram.com/mcube_games/?hl=en"
                        className="social" target="_blank" >
                        <FontAwesomeIcon icon={faInstagram} size="1x" />
                    </a>
                    <a href="https://play.google.com/store/apps/details?id=com.mcube.app&hl=en_IN&gl=US"
                        className="social" target="_blank" >
                        <FontAwesomeIcon icon={faGooglePlus} size="1x" />
                    </a>
                </li>
            </ul>   
        </div>
    )
}

export default SocialIcons