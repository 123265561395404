import { React } from 'react';

function UnderConstruct () {
return(
<div class="page-404 section--full-bg">
         <div class="container">
            <div class="row">
               <div class="col-12">
                  <div class="page-404__wrap">
                     <div class="page-404__content">
                        <h1 class="page-404__title">mcubegames.in is Upgrading!</h1>
                        <p class="page-404__text">We will be back soon with a brand new Look stay tuned.</p>
                        <p class="page-404__text">Follow us on Instagram or Facebook to Know More.</p>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
);
}
export default UnderConstruct;