import React from 'react';
import 'antd/dist/reset.css';
import { Select } from 'antd';

const { Option } = Select;

function handleChange(value) {
    console.log(`selected ${value}`);
}


function Included() {
    return (
        <div>
            <Select
                mode="multiple"
                style={{ width: '100%' }}
                placeholder="select product"
                defaultValue={['option1']}
                onChange={handleChange}
                optionLabelProp="label"
            >
                <Option value="option1" label="option1">
                    <div className="demo-option-label-item">
                        option1
                    </div>
                </Option>


                <Option value="option2" label="option2">
                    <div className="demo-option-label-item">
                        option2
                    </div>
                </Option>


                <Option value="option3" label="option3">
                    <div className="demo-option-label-item">
                        option3
                    </div>
                </Option>


                <Option value="option4" label="option4">
                    <div className="demo-option-label-item">
                        option4
                    </div>
                </Option>

            </Select>
        </div>
    )
}

export default Included