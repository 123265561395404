import UniversalNav from './header';
import UniversalFooter from './footer';

export default function CancellationPreOrderPolicy() {
	return (
		<div>
			<UniversalNav />
			<div className="col-md-12 information-entry" >
				<div className="article-container style-1">

					<h2>Cancellation and Refund Policy for Pre-order Product/Codes</h2>

					<p>Effective date: August 31, 2018</p>

					<p>Refund will not be issued For Pre-order Product to customer’s dissatisfaction with the product once code has been delivered to him.
For Any Reason Refund will not be issued One’s Customer Confirmed Pre-order Product</p>

					<p>Cancellation and Refund Policy for Digital Product/Codes :-</p>

					<p>Refund will not be issued due to customer’s dissatisfaction with the product once code has been delivered to him.</p>

					<p>Refund will not be issued if the customer has bought the wrong game once the code has been delivered to him.</p>

					<p>Refund will not be issued once the code has been delivered to the customer.</p>

					<p>In certain cases (Unprocessed transactions), refund will be processed only through the same mode of payment i.e. payment to same account which has been used during the transaction. 10% cancellation charges will be deducted on refund amount.</p>

					<p>Refunds will not be issued if customer’s computer does not meet the minimum product requirements.</p>

					<p>Orders of PC Digital Products and PSN Digital Products Like Fortnite, Xbox Etc. Codes cannot be cancelled or refunded for any reason.</p>

					<p>In the unlikely event that your digital key is not working, we will contact the key provider on your behalf. The key provider will carry out an investigation and only if they conclude that the key was actually not working, we will issue a replacement key and the old key will get blacklisted. This process will take a minimum of 21 working days. The decision of the key provider will be final in any such cases.</p>

                    <p>Refund On By Cancellation – Any refund requested by customer will attract 10% Penalty.</p>
					<p>Contact Us</p>

					<p>If you have any questions about this Shipping Devliery T&C, please contact us:</p>

					<ul>
						<li>By email: mcubegamesindia@gmail.com</li>
						<li>Phone - 022 4924 5872</li>
						<li>By visiting this page on our website: https://mcubegames.in/contact</li>
					</ul>

					<p>&nbsp;</p>
				</div>
			</div>
			<UniversalFooter />
		</div>

	);

}