import React, { useState, useEffect } from 'react';
import Spinner from 'react-bootstrap/Spinner'
import {
    Table, Form, Button
} from 'react-bootstrap';
import API_REQUEST from '../../../config/client';
import { Card } from 'antd';
import { useSelector } from "react-redux";
import Get_image from '../../../config/get_image';
import H5 from "../../ui/h5";
import {Image} from 'react-bootstrap';
import {Row, Col} from 'react-bootstrap'
import WriteReview from './writeReview';
import { Modal, Rate } from 'antd';
import { Link } from "react-router-dom";

function MyOrder() {

    const [profile, getProfile] = useState(null);
    const [orderDtls, orderDetails] = useState(null);
    const [curOrderId, getCurOrderId] = useState(null);
    const [canOrder, getCanOrder] = useState(null);
    const [ordId, getOrdId] = useState(null);
    const [orderId, getOrderId] = useState(null);
    var Displaycartdata = null;
    var DisplayOrderDetails = null;
    var DisplayOrderItems = null;
    const [modalText, setModalText] = useState('Please Write Your Review');
    const [modalText1, setModalText1] = useState('Please Write Your Query Here');
    const [reviewText, setReviewText] = useState("");
    const [supportText, setSupportText] = useState("");
    const [recordResponse, getRecordedResponse] = useState(0);
    const [visible, setVisible] = useState(false);
    const [visible1, setVisible1] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [handleStar, getHandleStar] = useState(0);
    const [productId, setProductId]  = useState(0);
    const [support, setSupport] = useState(0);

    // const link = "/product?id=" + orderDtls.ProductID;

    
    function handle(e) {
        setReviewText(e.target.value)
    }

    function handle1(e) {
        setSupportText(e.target.value)
    }

    async function fetchSingleProductDetails1() {
        var request_data = {action:"save_review", reviewText:reviewText,handleStar:handleStar, prodId: productId, customerId: customerId }
        await API_REQUEST('single_product', request_data).then((response) => { getRecordedResponse(response)})
    }
  
    const handleOk = async () => {
      setModalText('Please wait while we record you response');
      setConfirmLoading(true);  
      await fetchSingleProductDetails1()
    };

    async function fetchSingleProductDetails2() {
        var request_data = {action:"supportQuery", customerId: customerId, orderId: orderId, Description: supportText}
        await API_REQUEST('orderSummary', request_data).then((response) => {
            setSupport(response)
        })
    }

    //console.log(support)
  
    const handleOk1 = async () => {
      setModalText1('Please wait while we record you response');
      setConfirmLoading(true);  
      await fetchSingleProductDetails2()
    };

    useEffect(() => {
        if (support.status === 1) {
            setModalText('Our Team will get back to you')
            setTimeout(() => {
                setVisible1(false);
                setConfirmLoading(false);
            }, 2000);
        }
    }, [support])

    useEffect(() => {
        if(recordResponse.status === 1){
            setModalText('Thank you for submitting your valuable feedback')
            setTimeout(() => {
              setVisible(false);
              setConfirmLoading(false);
            }, 2000);
        }
    },[recordResponse])
  
    const handleCancel = () => {
      //console.log('Clicked cancel button');
      setVisible(false);
      setVisible1(false);
      
    };

    function handleStarChange(e) {
        getHandleStar(e)
    }

    const link ="";
    const {
        isLoggedIn
    } = useSelector(state => state.auth);
    const { user } = useSelector(state => state.auth);
    if (isLoggedIn) {
        var customerId = user.id;
        if (!customerId) {
            customerId = JSON.parse(user).id;
        }
    } else {
        customerId = null;
    }

    const onChangeS1 = (e) => {
        const fs1 = e.target.value;
        getOrdId(fs1);
        //console.log(ordId);
    };

      const showModal = (e) => {
      setProductId(e.target.value)
      setVisible(true);
    };

     const onSupport = (e) => {
         getOrderId(e.target.id)
         setVisible1(true);
     };

       const refreshWithOrderNo = async (e) => {
            var fetchProfile = async () => {
                await API_REQUEST('orderSummary', {
                    action: 'getOrderSummary',
                    customerId: customerId,
                    orderId: ordId
                }).then((response) => getProfile(response));
            }
            fetchProfile();
       }

    //API REQUEST to Fetch Order Summary
    useEffect(() => {
        var fetchProfile = async () => {
            await API_REQUEST('orderSummary', {
                action: 'getOrderSummary',
                customerId: customerId,
                orderId: ordId
            }).then((response) => getProfile(response));
        }
        fetchProfile();
    }, [customerId]);

    const getOrderDetails = async (e) => {
            e.preventDefault();
            //console.log(e)
            getCurOrderId(e.target.innerHTML);
            var request = {
                action: 'getOrderDetails',
                orderId: e.target.innerHTML,
                customerId: customerId
            }
            await API_REQUEST('orderDetails', request).then((response) => orderDetails(response));
    };

    const cancelOrder = async (e) => {
            e.preventDefault();
            //console.log(curOrderId);
            var request = {
                action: 'cancelOrder',
                orderId: curOrderId
            }
            await API_REQUEST('orderDetails', request).then((response) => getCanOrder(response));
            window.location.href = "/myorders";
        };

    if (orderDtls != null){

        DisplayOrderItems = orderDtls.Item.map(orderDtls => (
               <tbody>
                        <tr className="shop-cart-item" >
                            <td className="fag-cart-preview">
                                <Image style={{width: "30px", height: "40px"}} src={Get_image("products",orderDtls.ProductID,orderDtls.Image)} rounded />
                            </td>
                            <td className="fag-cart-product">
                                {/* <p><a href = {link} >{name}</a></p> */}
                                <p className="fag-cart-product-name"><a href={"/product/" + orderDtls.Slug}>{orderDtls.ProductName}</a></p>
                            </td>
                            <td className="fag-cart-price">
                                <p>{orderDtls.Price}</p>
                            </td>
                            <td className="fag-cart-quantity">
                                <p>{orderDtls.QTY}</p>
                            </td>
                            <td className="fag-cart-total">
                                <p>{orderDtls.ProdTotal}</p>
                            </td>
                            <td className="fag-cart-total">
                                <Button value={orderDtls.ProductID} onClick={showModal}>Review</Button>
                            </td>
                        </tr>
                    </tbody>
                    
        ));
    }

    if (orderDtls != null){
         DisplayOrderDetails = orderDtls.Data.map(orderDtls => (
            <div>
                {/* Block 1 */}
            <Card title={"Order Number #" + orderDtls.OrderId}  id="order-sum-title" bordered={false} style={{ backgroundColor: "#080811", color: "#fff", fontColor: "#fff" }}>
                <div className="row">
                    <div className="col" >
                        <p style={{ marginBottom: "0.5rem" }}>Status :-</p>
                    </div>
                    <div className="col" style={{ color: "#ff7a21" }}>
                        <p style={{ marginBottom: "0.5rem" }}>{orderDtls.Status}</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <p style={{ marginBottom: "0.5rem" }}>Order Date :-</p>
                    </div>
                    <div className="col" style={{ color: "#ff7a21" }}>
                        <p style={{ marginBottom: "0.5rem" }}>{orderDtls.OrderDate}</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <p style={{ marginBottom: "0.5rem" }}>Support/Help :-</p>
                    </div>
                    <div className="col" style={{ color: "#ff7a21" }}>
                        <Image  id={orderDtls.OrderId} onClick={onSupport} src="https://img.icons8.com/ios-filled/26/FF7A21/online-support.png"/>
                    </div>
                </div>
                {
                    orderDtls.VoucherCode === "" ? 
                     <p></p>  : <div className = "row" >
                            <div className="col" >
                                    <p style={{ marginBottom: "0.5rem" }}>Voucher Applied :-</p>
                                </div>
                                <div className="col" style={{ color: "#ff7a21" }}>
                                <p style={{ marginBottom: "0.5rem" }}>{orderDtls.VoucherCode}</p>
                            </div>
                        </div> 
                }
                {
                    orderDtls.SNotes  ? 
                      <div className = "row" >
                            <div className="col" >
                                    <p style={{ marginBottom: "0.5rem" }}>Mcubegames Notes :-</p>
                                </div>
                                <div className="col" style={{ color: "#ff7a21" }}>
                                <p style={{ marginBottom: "0.5rem" }}>{orderDtls.SNotes}</p>
                            </div>
                        </div> :<p></p>
                }
            </Card>
            {
                orderDtls.TrackingNumber ? 
                // <br></br>
            <Card title="Track Your Order"  id="order-sum-title" bordered={false} style={{ backgroundColor: "#080811", color: "#fff", fontColor: "#fff", marginTop:"10px" }}>
                    <div>
                        <p style={{ marginBottom: "0.5rem" }}>You Can Track your Order from {orderDtls.CourierPartner} here's your Tracking Number {orderDtls.TrackingNumber} </p> 
                    </div>
            </Card> : <p></p>
            }

            {/* Block 2 */}
           <div className="cart-table-left">
            <div className="table-responsive cart_box">
                <Table className="table" style={{color: "#fff !important"}}>
                    <thead>
                        <tr>
                            <th>Image</th>
                            <th>Product</th>
                            <th>Price</th>
                            <th>Quantity</th>
                            <th>Total</th>
                        </tr>
                    </thead>
                     {
                         DisplayOrderItems ? DisplayOrderItems: <Spinner animation="border" variant="secondary" />
                     }
                </Table>
            </div>
        </div>

        {/* Block 3 */}
        <div className="row" style={{marginTop:"10px"}}>
            <div className="col-lg-6">
                <Card title="Shipping Address" id="order-sum-title" bordered={false} style={{ backgroundColor: "#080811", color: "#fff", fontColor: "#fff" }}>
                <div className="row">
                    <div className="col" >
                        <p style={{ marginBottom: "0.5rem" }}>{orderDtls.BillTo}</p>
                        <p style={{ marginBottom: "0.5rem" }}>{orderDtls.sAddress1}, {orderDtls.sAddress2}, {orderDtls.scity}, {orderDtls.sStateId}, {orderDtls.bPincode}, {orderDtls.sCountryId}  </p>
                        <p style={{ marginBottom: "0.5rem" }}>Ph: {orderDtls.bContactNumber}</p>
                    </div>
                </div>
            </Card>
            </div>
            <div className="col-lg-6">
                  <Card title="Payment" id="order-sum-title" bordered={false} style={{ backgroundColor: "#080811", color: "#fff", fontColor: "#fff" }}>
                     <div className="row">
                        <div className="col" >
                            <p style={{ marginBottom: "0.5rem" }}>Sub-Total :-</p>
                        </div>
                        <div className="col">
                           <p style={{ marginBottom: "0.5rem" }}>{orderDtls.TotalPrice} ₹</p>
                       </div>
                    </div>
                    <div className="row">
                       <div className="col" >
                            <p style={{ marginBottom: "0.5rem" }}>Discount :-</p>
                        </div>
                        <div className="col">
                           <p style={{ marginBottom: "0.5rem" }}>{orderDtls.discountAmount}₹</p>
                       </div>
                    </div>
                    {
                       orderDtls.isWallet == '1' ? 
                       <div className = "row" >
                            <div className="col" >
                                    <p style={{ marginBottom: "0.5rem" }}>Payment from Wallet :-</p>
                                </div>
                                <div className="col">
                                <p style={{ marginBottom: "0.5rem" }}>{orderDtls.walletBalance}₹</p>
                            </div>
                        </div> : <p></p>
                   }
                    <div className="row">
                       <div className="col" >
                            <p style={{ marginBottom: "0.5rem" }}>Total Amount :-</p>
                        </div>
                        <div className="col">
                           <p style={{ marginBottom: "0.5rem" }}>{orderDtls.PayableAmount}₹</p>
                       </div>
                    </div>
                    < div className = "row" >
                       <div className="col" >
                            <p style={{ marginBottom: "0.5rem" }}>Payment Mode :-</p>
                        </div>
                        <div className="col">
                           <p style={{ marginBottom: "0.5rem" }}>{orderDtls.paymentType}</p>
                       </div>
                   </div>
            </Card>
            {
               orderDtls.Status === 'Processing' || orderDtls.Status === 'Order Placed' ? <button type="submit" onClick={cancelOrder} style={{ borderRadius: "10px",marginTop: "5px" }} className="fag-btn">Cancel Order</button> : <p></p> 
            }
            </div>
        </div>
            </div>
            ));
    }

    if (profile != null) {
        Displaycartdata = profile.Data.map(profile => (
            <div>
                
                <Card title="Order Number # " id="order-sum-title" bordered={false} style={{ backgroundColor: "#080811", color: "#fff", fontColor: "#fff" }}>
                    <div className="row">
                    
                    {/* <Link to="#order-sum-title"  href="#order-sum-title" className="ptag" orderID onClick={getOrderDetails} value={profile.OrderId}>{profile.OrderId}</Link> */}

                    <p className="ptag" orderID onClick={getOrderDetails} value={profile.OrderId}>{profile.OrderId} </p>
                
                        <div className="col">
                            <p style={{ marginBottom: "0.5rem" }}>Status :-</p>
                        </div>
                        <div className="col" style={{ color: "#ff7a21" }}>
                            <p style={{ marginBottom: "0.5rem" }}>{profile.Status}</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <p style={{ marginBottom: "0.5rem" }}>Order Date :-</p>
                        </div>
                        <div className="col">
                            <p style={{ marginBottom: "0.5rem" }}>{profile.OrderDate}</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <p style={{ marginBottom: "0.5rem" }}>Amount :-</p>
                        </div>
                        <div className="col">
                            <p style={{ marginBottom: "0.5rem" }}>{profile.PayableAmount}</p>
                        </div>
                    </div>
                </Card> 
                
            </div>
        ));
    }

    const components = {
        orderDetails: <div className="single-contact-box" >
                  <H5 name1={'Order '} name2={'Details'} id="details"/>
                
            {
                DisplayOrderDetails? DisplayOrderDetails: <Image src="https://img.icons8.com/doodle/100/000000/empty-box.png"/>
            }
        </div>
    };



    const [render, updateRender] = useState("orderDetails");
    const handleMenuClick = menu => {
        //.log(menu.target.value);
        updateRender(menu.target.value);
    };

    return (
        <>
        <div className="mt-70">
            <Row>
                <Col md={6}></Col>
                <Col md={6} className="writeReview">
                    <Modal
                        className="reviewAll"
                        title="Customer Review"
                        visible={visible}
                        onOk={handleOk}
                        confirmLoading={confirmLoading}
                        onCancel={handleCancel}
                    >
                        <p>{modalText}</p>

                        <textarea onChange={(e) => handle(e)} value={reviewText} className="comment" placeholder="Review..." name="comment" rows="5" cols="45"></textarea>
                        <br></br>

                        <Rate
                            allowHalf
                            style={{ color: '#ffa11a' }}
                            onChange={(e) => handleStarChange(e)}
                            value={handleStar}
                        />  
                    </Modal>
                </Col>
            </Row>
        </div>

        <div>
            <Row>
                <Col md={6}></Col>
                <Col md={6} className="writeReview">
                    <Modal
                        className="reviewAll"
                        title="Customer Support"
                        visible={visible1}
                        onOk={handleOk1}
                        confirmLoading={confirmLoading}
                        onCancel={handleCancel}
                    >
                        <p>{modalText1}</p>

                        <textarea onChange={(e) => handle1(e)} value={supportText} className="comment" placeholder="Write your Query here..." name="comment" rows="5" cols="45"></textarea>
                        <br></br>

                    </Modal>
                </Col>
            </Row>
        </div>
            <section className="fag-contact-details-area section_100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="fag-table-scroll" style={{ overflowY: "scroll", height: "300px", scrollbarColor: "#ff7a21  !important", scrollbarWidth: "thin  !important" }}>
                                <div className="contact-icon">
                                    <H5 name1={'Order '} name2={'Summary'} />
                                    <Form onSubmit={refreshWithOrderNo}>
                                        <Form.Group controlId="formBasicEmail">
                                            <div className="row">
                                                <div className="col-lg-10">
                                                    <div className="form-shop">
                                                        <Form.Control className='inpText'
                                                            type="name"
                                                            name="orderId"
                                                            id="s1"
                                                            value={ordId}
                                                            onChange={onChangeS1}
                                                            placeholder="Search your Order No " Refresh />
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <Image src="https://img.icons8.com/windows/32/ffffff/refresh.png" onClick={refreshWithOrderNo} />
                                                </div>
                                            </div>
                                        </Form.Group>
                                    </Form>
                                </div>
                                <div id="my-order-left" className="profile-div" style={{ border: "none", borderRadius: "15px", padding: "10px" }}>
                                    {/* <button type="submit" className="fag-btn" key="1" name="orderDetails" value="orderDetails" onClick={handleMenuClick}> My Profile </button> */}
                                    {
                                        Displaycartdata ? Displaycartdata : <p>No Orders Yet</p>
                                    }
                                </div>
                            </div>
                        </div>

                        {
                            <div className="col-lg-8"> {components[render]} </div>
                        }
                    </div>
                </div>
            </section>

        </>
    );
}

export default MyOrder;