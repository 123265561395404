import UniversalNav from './header';
import UniversalFooter from './footer';

export default function CancellationRefundPolicy() {
	return (
		<div>
			<UniversalNav />
			<div className="col-md-12 information-entry" >
				<div className="article-container style-1">

					<h2>Cancellation and Refund Policy for Physical Product/Codes</h2>

					<p>Effective date: August 31, 2018</p>

					<p>Company provides refund only in case of faulty and damaged condition, after investigation.</p>

					<p>For PC games, kindly be aware of the high systems requirements before making of its purchase as there are no refunds or returns possible for PC games.</p>

					<p>Refund will be processed for the cancelled order only through the same mode of payment i.e. payment to same account which you used during the transaction.</p>

					<p>For Credit card/Debit card mode of payment, refund processing time as per bank’s standard time frame which is approximately 8-10 business days. For COD/cheque/DD mode of payment, refund processing time is 15-20 working days.</p>

					<p>Cheque will be made as per Billing Name provided. For non-delivered items, refund will be processed only on confirmation that the product was not delivered to you and you choose to take a refund and are not interested in any other product.</p>

					<p>On Prepaid order, 10% cancellation charges will be deducted on refund amount.</p>

					<p>All rights reserved with Mcube Games. To be updated, kindly read the Terms and Conditions from time to time.</p>

					<ul>
						<li>By email: mcubegamesindia@gmail.com</li>
                        <li>Phone - 022 4924 5872</li>
						<li>By visiting this page on our website: https://mcubegames.in/contact</li>
					</ul>

					<p>&nbsp;</p>
				</div>
			</div>
			<UniversalFooter />
		</div>

	);

}