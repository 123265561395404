import React from "react";

import 'bootstrap-css-only/css/bootstrap.min.css';
import '../../../assets/css/style.css'

const Items = (props) => {

    const { name , subtotal , quantity } = props;

    return (
    <>
        <tr>
            <td>
                <p>{name} × ( {quantity} )</p>
            </td>
            <td>
                <p>₹ {subtotal}</p>
            </td>
        </tr>
                              
    </>
  );
};

export default Items;