import React,{ useState  } from "react";
import { Button, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from "react-redux";
// import {Link} from 'react-router';
import Topic from "../ui/topic";
import avatar from "../../assets/img/mg-1.png";
import { register } from "../../actions/auth";

function Register(){

  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [successful, setSuccessful] = useState(false);
  var md5 = require("md5");


  const { message } = useSelector(state => state.message);

  const dispatch = useDispatch();

  const onChangeUsername = (e) => {
    const username = e.target.value;
    setUsername(username);
  };

  const onChangeEmail = (e) => {
    const email = e.target.value;
    setEmail(email);
  };

  const onChangePhone = (e) => {
    const phone = e.target.value;
    setPhone(phone);
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };

  const handleRegister = (e) => {
    e.preventDefault();
   // setLoading(true);
    setSuccessful(false);
    var request = {action:'register','username':username, 'email':email,'phone':phone,'password':md5(password)}


    dispatch(register(request))
      .then((response) => {
        //console.log(response)
        setSuccessful(true);
        setUsername('');
        setEmail('');
        setPhone('');
        setPassword('');
      })
      .catch(() => {
        setSuccessful(false);
      });
   // setLoading(false);
  };

// if (message === 'Registration Done') {
//     return <Redirect to="/login" />;
//   }
   

  return (
    <div class="page-404 section--full-bg">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="page-404__wrap">
                <div class="login-wrapper">
                  <div  className="form-row">
                      <img className="login_user" src={avatar} alt="login user" />
                    </div>
                    <Topic name1={''} name2={'Register'}/>
                    <Form onSubmit={handleRegister}>
                        <Form.Group controlId="formBasicName">
                            <div class="form-row">
                            <Form.Control className='inpText' name="username" value={username} onChange={onChangeUsername} type="name" placeholder="Username" />
                            </div> 
                        </Form.Group>

                        <Form.Group controlId="formBasicEmail">
                          <div class="form-row">
                            <Form.Control className='inpText' name="email" value={email} onChange={onChangeEmail} type="email" placeholder="Email" />
                          </div>  
                        </Form.Group>

                        <Form.Group controlId="formBasicEmail">
                          <div class="form-row">
                            <Form.Control className='inpText' name="phone" value={phone} onChange={onChangePhone} type="phone" placeholder="Mobile Number" />
                          </div>  
                        </Form.Group>
                      
                        <Form.Group controlId="formBasicPassword">
                          <div class="form-row">
                            <Form.Control className='inpText'  type="password" name="password" value={password} onChange={onChangePassword} placeholder="Password" />
                          </div>  
                        </Form.Group>

                        {/* <Form.Group controlId="formBasicCheckbox">
                          <div class="form-row">
                            <Form.Check type="checkbox" />
                            <span><a class="fa fa-caret-right" href="/forgot">Privacy Policy</a></span>
                          </div>  
                        </Form.Group> */}

                        <div  class="form-row">
                            <Button className='fag-btn' type="submit">
                                Register
                            </Button>
                        </div>
                        <div class="form-row">
                            <Form.Text>Already have an Account <a class="fa fa-caret-right" href="/login">Login?</a></Form.Text>
                        </div>
                    
                         {message && (
                          <div className="form-group">
                            <div className={ successful ? "alert alert-success" : "alert alert-danger" } role="alert">
                              {message} 
                            </div>
                          </div>
                        )}
                  
                         
                    </Form>
                </div>
              </div>
            </div>
        </div>
      </div>
    </div>    
  );
}



export default Register;