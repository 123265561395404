import React, { useState, useEffect, useRef } from "react";
import API_REQUEST from "../../../config/client";
import { Row, Col, Form, Button } from "react-bootstrap";
import { Select, Option } from "antd";
import axios from 'axios'
import { CSVLink, CSVDownload } from "react-csv";
import { FileDrop } from 'react-file-drop'
import CONST_URL from "../../../config/url";
import CONST_JSON from "./bulk_header";
import CSVReader from "react-csv-reader";


function BulkUplaod() {

    const [CsvHeader, getCsvHeader] = useState([])
    const [CsvBody, getCsvBody] = useState([])
    const [options, setOptions] = useState(null);
    const [include, setInclude] = useState(null);
    const [excproduct, getExcProduct] = useState(null);
    const [csvResults, getcsvResult] = useState([]);
    const [csvData, setCsvData] = useState(null);
    const [csvStoreResults, getCsvStoreResults] = useState(null)
    const [successful, setSuccessful] = useState(false);

 
    const { Option } = Select;
    var items = [];

    const csvLink = useRef()
    const [fileData, setData] = useState({
        include_product_ids: []
      });

    function handleEmptyCSVTab() {
        var request = {
            action: "getEmptyProductCSV",
            data: fileData
        };
        var BASE_URL = 'https://mcubegames.in:8443/mcube_ecomm/mcubefront/api/Database/admin/'
        // var BASE_URL = 'http://localhost/mcube_ecomm/mcubefront/api/Database/admin/'
        axios.post(BASE_URL + 'bullk_operation.php', request).then((response) => {
            console.log(response.data)
            setCsvData(response); 
        })
        .catch((e) => console.log(e)
        )
    }

    useEffect(() => {
        let isMounted = true;
        if (csvData !== null){
            if (isMounted){
                console.log(CONST_JSON)
                getCsvHeader(CONST_JSON);
                getCsvBody(csvData.data.data_body);
            }
        }

        return () => {
            isMounted = false
        };

    }, [csvData]);

    function handleChange(value) {
        setData(fileData => ({
            ...fileData,
            include_product_ids: value
        }));
    }

    useEffect(() => {
        var fetchAddress = async () => {
        var request = {
            action: 'getExcludedProd',
        }

        await API_REQUEST('fetch', request).then((response) => getExcProduct(response));
        }
        fetchAddress();
    }, []); 


    useEffect(() => {
        if(CsvHeader.length > 0 ){
            csvLink.current.link.click();
        }
    },[CsvHeader])

    useEffect(() => {
        if (excproduct != null) {
          excproduct.data.map(i => (
            items.push(<Option key={i.ProductID}>{i.Name}</Option>)
          ))
          setInclude(items);
          getExcProduct(null);
          }
      }, [items]);

    const handleForce = (data_csv_uplaod, fileInfo) => {

        var request = {
            action: "storeExternalCSV",
            data: data_csv_uplaod
        };
        var BASE_URL = 'https://mcubegames.in:8443/mcube_ecomm/mcubefront/api/Database/admin/'
    //    var BASE_URL = 'http://localhost/mcube_ecomm/mcubefront/api/Database/admin/'
        axios.post(BASE_URL + 'bullk_operation.php', request).then((response) => {
            data_csv_uplaod = [];
        })
        
        setSuccessful(true)
    };

    const papaparseOptions = {
        header: true,
        dynamicTyping: true,
        skipEmptyLines: true,
        // transformHeader: header => header.toLowerCase().replace(/\W/g, "_")
      };
      
    return(
        <div className="container_admin admin_body form">
            <div class="navBtnGroup_admin">
                <Button onClick={handleEmptyCSVTab}>Download Product CSV</Button>
                {CsvHeader.length > 0 &&
                    // <div>hello</div>
                    <CSVLink
                        headers = {CsvHeader}
                        data={CsvBody}
                        filename='Product_Upload.csv'
                        className='hidden'
                        ref={csvLink}
                        target='_blank'
                    />
               }
                
            </div>
            <br></br>
            <div className="col-sm-5">
                <label>Products</label>
            </div>
            <div className="preview-values">
                {options}
            </div>
            <div className="col-sm-5">
                <Select mode="tags" style={{ width: '100%' }} placeholder="Included Products" onChange={handleChange}>
                    {include}
                </Select>
            </div>

            <br></br>
            <div className="container">
                <CSVReader
                    cssClass="react-csv-input"
                    label="Uplaod Product Csv generated from above"
                    parserOptions={papaparseOptions}
                    onFileLoaded={handleForce}
                />
                {/* <Button onClick={handleuploadedcsv}>Uplaod Products</Button> */}
            </div>    
            { successful ===true ? 
             <div className="form-group">
               <div className={ successful ? "alert alert-success" : "" } role="alert">
                 <p>File Uploaded Succesfully!</p>
               </div>
             </div> : <></>
            }
        </div>
    
    )
}

export default BulkUplaod;