import React from 'react'
import UniversalFooter from './footer'
import UniversalNav from './header'

function TermsAndCondition() {
    return (
        <>
            <div className="col-md-12 information-entry">
                <div className='article-container style-1'>
                    <UniversalNav />
                    <h2>TERMS & CONDITIONS</h2>
                    <p>This page states the Terms and Conditions under which you (Visitor) may visit this website <a href="https://mcubegames.in/" >mcubegames.in</a>. Please read this page carefully. If you do not accept the Terms and Conditions stated here, we would request you to exit this site. The business, any of its business divisions and / or its subsidiaries, associate companies or subsidiaries to subsidiaries or such other investment companies (in India or abroad) reserve their respective rights to revise these Terms and Conditions at any time by updating this posting. You should visit this page periodically to re-appraise yourself of the Terms and Conditions, because they are binding on all users of this Website.</p>
                    <br></br>

                    <h5>TRADEMARK AND LICENSE</h5>
                    <p> All images, characters and logos are properties of their respective owners. Material on Website is solely for your personal, non-commercial use. You must not copy, reproduce, republish, upload, post, transmit or distribute such material in any way, including by email or other electronic means and whether directly or indirectly and you must not assist any other person to do so. Without the prior written consent of the owner, modification of the materials, use of the materials on any other website or networked computer environment or use of the materials for any purpose other than personal, non-commercial use is a violation of the copyrights, trademarks and other proprietary rights, and is prohibited. Any use for which you receive any remuneration, whether in money or otherwise, is a commercial use for the purposes of this clause. </p>
                    <br></br>

                    <h5>ACCEPTABLE WEBSITE USE</h5>
                    <p>(A) Security Rules
                        Visitors are prohibited from violating or attempting to violate the security of the Web site, including, without limitation, (1) accessing data not intended for such user or logging into a server or account which the user is not authorised to access, (2) attempting to probe, scan or test the vulnerability of a system or network or to breach security or authentication measures without proper authorisation, (3) attempting to interfere with service to any user, host or network, including, without limitation, via means of submitting a virus or “Trojan horse” to the Website, overloading, “flooding”, “mail bombing” or “crashing”, or (4) sending unsolicited electronic mail, including promotions and/or advertising of products or services. Violations of system or network security may result in civil or criminal liability. The business and / or its associate entities will have the right to investigate occurrences that they suspect as involving such violations and will have the right to involve, and cooperate with, law enforcement authorities in prosecuting users who are involved in such violations.</p>
                    <p>(B) General Rules
                        Visitors may not use the Web Site in order to transmit, distribute, store or destroy material (a) that could constitute or encourage conduct that would be considered a criminal offence or violate any applicable law or regulation, (b) in a manner that will infringe the copyright, trademark, trade secret or other intellectual property rights of others or violate the privacy or publicity of other personal rights of others, or (c) that is libellous, defamatory, pornographic, profane, obscene, threatening, abusive or hateful. Product purchased on this site is strictly for end user consumption and not for resell, trade, gifting. Website will not responsible for any kind of misusage of product by buyers. Action will be taken for such buyer as per law and regulation.
                    </p>
                    <br></br>

                    <h5>INDEMNITY</h5>
                    The User unilaterally agree to indemnify and hold harmless, without objection, the Company, its officers, directors, employees and agents from and against any claims, actions and/or demands and/or liabilities and/or losses and/or damages whatsoever arising from or resulting from their use of <a href="https://mcubegames.in/" >mcubegames.in</a> or their breach of the terms .
                    <br></br>
                    <br></br>

                    <h5>LIABILITY</h5>
                    User agrees that neither <a href="https://mcubegames.in/" >mcubegames.in</a> nor its group companies, directors, officers or employee shall be liable for any direct or/and indirect or/and incidental or/and special or/and consequential or/and exemplary damages, resulting from the use or/and the inability to use the service or/and for cost of procurement of substitute goods or/and services or resulting from any goods or/and data or/and information or/and services purchased or/and obtained or/and messages received or/and transactions entered into through or/and from the service or/and resulting from unauthorized access to or/and alteration of user’s transmissions or/and data or/and arising from any other matter relating to the service, including but not limited to, damages for loss of profits or/and use or/and data or other intangible, even if <a href="https://mcubegames.in/" >mcubegames.in</a> has been advised of the possibility of such damages.
                    <br></br>
                    <br></br>

                    <h5>DISCLAIMER OF CONSEQUENTIAL DAMAGES</h5>
                    In no event shall Company or any parties, Organization or entities associated with the corporate brand name us or otherwise, mentioned at this Website be liable for any damages whatsoever (including, without limitations, incidental and consequential damages, lost profits, or damage to computer hardware or loss of data information or business interruption) resulting from the use or inability to use the Website and the Website material, whether based on warranty, contract, tort, or any other legal theory, and whether or not, such organizations or entities were advised of the possibility of such damages.
                    <br></br>
                    <br></br>

                    <h5>PRE ORDERS</h5>
                    Pre Order is one of the services we are offering to our customers. By pre-ordering it is taken that you intend to pre-pay up front and wait to receive your product as that is what a pre-order implies. Once the Pre-order booking is placed it will not be revoked or cancelled under any circumstance.
                    Generally pre-ordered product is shipped on or after the day of its release. We try utmost to ship the pre-order products as soon as it is available with us. However the company is not liable for any sort of delay in the delivery or shipment of them as it depends on the availability and its supply by its supplier(s). Pre-order applies only to not-yet-released products. It does not apply to other product lines or to items that have already been released in India. Invoice for pre-ordered products will be generated only when the product is shipped.
                    Pre-order applies only to items displaying the Pre-order offer message on the product listing or product description page on the website. To read rest of the policies of the site kindly check Terms and Conditions page.
                    <br></br>
                    <br></br>

                    <h5>PAYMENT AND SECURITY</h5>
                    There are lot of options for making payments now a days like credit cards, debit cards, cash on delivery. You can be assured with the trusted payment gateway partners as they use secure encryption technology to keep your transaction details confidential at all times. However, the following points are needed to be kept in mind while using these options: You can use Visa, Master Card, Maestro Card for making payments Order now and if price increases after your order, the current price of your order will be applicable. Debit cards, Credit Cards are accepted.
                    While using your credit/debit card you can increase the protection of your cards by entering the CVV and 3-D secure password when making the payments
                    Do not share the CVV / 3-D secure password / OTP to anyone
                    We as a merchant shall be under no liability whatsoever in respect of any loss or damage arising directly or indirectly out of the decline of authorization for any Transaction, on Account of the Cardholder having exceeded the preset limit mutually agreed by us with our acquiring bank from time to time.
                    <br></br>
                    <UniversalFooter />
                </div>
            </div>
        </>
    )
}

export default TermsAndCondition