import React, { useEffect } from 'react';
// import Banner from '../components/header/banner';
import UniversalNav from '../components/header/navBar_component';
import Cont_details from '../components/content-section/contact/cont_details';
import Contact_form from '../components/content-section/contact/contact_form';
// import '../assets/css/style.css';
// import '../assets/css/responsive.css';
// import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import Jwt_Decode from 'jwt-decode';
import { Helmet } from 'react-helmet';
import FooterDesktop from '../components/footer/footer_component';

if (localStorage.getItem("tok")) {
    const jwt_Token_decoded = Jwt_Decode(localStorage.getItem("tok"));
    if (jwt_Token_decoded.data.exp * 1000 < Date.now()) {
        localStorage.clear();
        window.location.href = "/login";
    }
}

function Contact() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <Helmet>
                <title>Mcube Games Official Site: PS, Xbox, Nintendo & PC Consoles, Games, Accessories & Merchandise in India</title>
                <meta name="description" conttent="Experience the new generation of games and entertainment with Xbox, Playstation, Nintendo also explore consoles, New and old games with Mcubegames" />
                <meta name="keywords" content="Playstation, playstation 4,sony playstation 5 stores,ps3, ps5 games,ps plus,ps4 games,playstation plus,playstation 3,ps vita,playstation 2,play station 5,ps 4,playstation 5 console,sony playstation ps5 console,ps4 console,playstation 1,ps4 price,ps5 digital,xbox series x,xbox series s,xbox one,xbox one s,xbox 360,xbox one x,xbox controller,xbox x,xbox one controller,xbox live gold,microsoft xbox series x,xbox one series x,series x,wii u,nintendo wii,wii console,nintendo wii u,wii controller,wii remote,wii u console,nintendo wii console, ps4 controller,ps5 controller,joystick,dualshock 4,gamepad,joystick ps4,ps3 controller,dualsense,steam controller,razer kishi,pc controller,game controller,ps2 controller,backbone controller,ps4 game controllers,ps5 media remote,ps5 remote,ps4 controller on pc,game pad,ps4 remote,sony ps4 controller,dualshock 4 controller,best controller for pc,gaming headset,bluetooth headset,logitech g pro x,best gaming headset,razer blackshark v2,hyperx headset,gaming headphones,logitech g733,razer headset,plantronics headset,headphones with mic,logitech headset,ps4 headset,jabra headset,arctis 7,jabra evolve 65,jabra evolve 75,wireless headset,wired headphones,corsair virtuoso,arctis pro wireless,headset with mic,jbl quantum 100,corsair headset,logitech pro x" />
            </Helmet>
            <UniversalNav />
            {/* <Banner header={'Contact Page'}/> */}
            <section class="fag-contact-details section_100 mt-70">
                <Cont_details />
                <Contact_form />
            </section>

            <FooterDesktop />
        </>
    );
}

export default Contact;
