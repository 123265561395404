import React, { useState, useEffect, useCallback, Component } from 'react'
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Container, Row, Col, Button, Card, Image } from 'react-bootstrap'
import { Rate, Spin } from 'antd';
import { HeartOutlined } from '@ant-design/icons';
import { LoadingOutlined } from '@ant-design/icons';
import renderHTML from 'react-render-html';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'antd';
import logo from '../../../assets/img/master-card.jpg'
import { GetProductCategoriesData, GetProductPriceHtml, CheckProductQuantity } from '../../utils/mcube_helper/jems_db_helper'
import { HandleCartOnSubmit, OpenNotification } from '../../utils/mcube_helper/purchase_item'
import HandleBuyNowOnSubmit from '../../utils/mcube_helper/buynow_item';
import QuantityButton from '../../utils/quantity/qty_btn'
import OptionTabs from './option_tabs'
import ProductImageGallery from './image_galley'
import API_REQUEST from '../../../config/client';
import { Helmet } from 'react-helmet';
import { useLocation, useParams } from 'react-router-dom';
import Get_direct_image from '../../../config/get_direct_image';
import Get_image from '../../../config/get_image';
import Img from 'react-cool-img';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBackward, faForward } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
// import  SnapmintScript  from '../Snapmit';

function ProductDetails() {
    const { slugurl } = useParams();
    const [SingleProductDetails, getProductDetails] = useState(null)
    const [productId, getProductId] = useState(null);
    const [title, getTitle] = useState(null);
    const [desc, getDesc] = useState(null);
    const [keyword, getKeyWord] = useState(null);
    const [productReview, getProductReview] = useState(null)
    const [notify, setNotify] = useState(null)
    const [counter, getCounter] = useState(1)
    const [buttonText, setButtonText] = useState('Notify');
    const [stock, setStock] = useState("In Stock");
    const [productQty, getProductQty] = useState(null);
    const [offers, getOffers] = useState("");
    const [urllist, getUrlList] = useState(null);
    const [ImageUrlList, getImageUrlList] = useState(null);
    const [relatedProds, getRelatedProds] = useState([]);
    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
    var Displaypdata = null;
    var Displayoffer = null;


    function sleeper(ms) {
        return function (x) {
            return new Promise(resolve => setTimeout(() => resolve(x), ms));
        };
    }

    // Scroll to top when page renders
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleImageClick = (slug) => {
        window.location.href = `/product/${slug}`;
    };


    //usesel#tor of logged in users
    const { isLoggedIn } = useSelector(state => state.auth);
    const { user } = useSelector(state => state.auth);
    if (isLoggedIn) {
        var customerId = user.id;
        if (!customerId) {
            customerId = JSON.parse(user).id;
        }
    } else {
        customerId = null;
    }

    // if empty for temo REMOVE and replace by loader
    var productLoader = <LoadingOutlined style={{ fontSize: 24 }} spin />;

    // fetch product api
    useEffect(() => {
        let isMounted = true;
        var request = { action: 'singleProduct_details', product_slug: slugurl }
        async function fetchSingleProductDetails() {
            await API_REQUEST('single_product', request).then((response) => {
                getProductDetails(response);
                getProductId(response.Data.id);
                getTitle(response.Data.MetaTitle);
                getDesc(response.Data.MetaDescription);
                getKeyWord(response.Data.MetaKeyword);
            })
        }
        fetchSingleProductDetails()
        return () => { isMounted = false };
    }, []);

    useEffect(() => {
        let isMounted = true;

        var fetchQty = async () => {
            await API_REQUEST('jems_db_helper', {
                action: 'get_product_qty',
                id: productId
            }).then((response) => {
                if (isMounted) getProductQty(response)
            });
        }
        var request_review = { action: 'get_review', product_id: productId, CustomerID: customerId }
        async function getproductreview() {
            await API_REQUEST('single_product', request_review).then((response) => { if (isMounted) getProductReview(response) })
        }
        fetchQty();
        getproductreview();
        return () => {
            isMounted = false
        };
    }, [productId]);


    // API REQ FOR RELATED PRODUCTS // 

    useEffect(() => {
        var fetchRelatedProducts = async () => {
            await API_REQUEST('fetchData', { action: 'getRelatedProduct', 'ProductID': productId }).then(sleeper(1)).then((response) => getRelatedProds(response));
        }
        fetchRelatedProducts();
    }, [productId]);



    const handlecart = useCallback((data) => {
        HandleCartOnSubmit(data)
        OpenNotification(SingleProductDetails.Data.Name)
    }, [SingleProductDetails])

    const handleNotify = async (data) => {
        var request = {
            action: 'setNotification',
            customerId: customerId,
            prodId: productId
        }
        await API_REQUEST('single_product', request).then((response) => setNotify(response));
        setButtonText('We Will Notify You!')
    };

    const handlebuynow = useCallback((data) => {
        HandleBuyNowOnSubmit(data)
    }, [productId])


    useEffect(() => {
        var fetchOffers = async () => {
            await API_REQUEST('singleProductOffer', { action: 'fetchOffer', 'ProductID': productId }).then((response) => getOffers(response));
        }
        fetchOffers();
    }, [SingleProductDetails]);

    useEffect(() => {
        var getimageResults = async () => {
            await API_REQUEST('single_product', { action: 'get_images_path', product_id: productId }).then((response) => getUrlList(response))
        }
        getimageResults()
    }, [productId])

    var list = [];

    useEffect(() => {
        if (urllist != null) {
            urllist.Data.map(i => (

                list.push(Get_direct_image("products/" + productId, i.path))
            ))

            getImageUrlList(list);
            getUrlList(null);

        }
    }, [urllist]);



    if (offers.message === "Inclusive Success") {
        Displayoffer = offers.Data.map((item) =>
            <p><span className="margin-5">Offers:</span><br />({item.vouchercode}) {item.title}
                <Card.Link href={"/know-more?id=" + item.vid}> Know More</Card.Link><br /></p>
        );
    }
    if (offers.message === "Exclusive Success") {
        Displayoffer = offers.Data.map((item) =>
            <p><span className="margin-5">Disclaimer:</span><br />({item.vouchercode}) {item.title} <br /></p>
        );
    }


    // // // // Slide 6 products on arrow click // // // //

    const products = relatedProds?.Data || [];
    const slides = [];
    let slideProducts = [];

    for (let i = 0; i < products.length; i++) {
        if (i % 6 === 0 && i !== 0) {
            slides.push(slideProducts);
            slideProducts = [];
        }
        slideProducts.push(products[i]);
    }
    if (slideProducts.length > 0) {
        slides.push(slideProducts);
    }



    const handleParentCounter = (count) => {
        getCounter(count)
    }

    const LoadSingleProductComponent = () => (
        <Container>
            <Row>
                <Col lg={6}>
                    <ProductImageGallery product_id={productId} />
                </Col>
                <Col lg={6}>
                    <div className="product-details-text positions">
                        <h3>{SingleProductDetails.Data.Name}  </h3>
                        
                        {/* <div className="tour-rating">
<Rate
disabled
allowHalf
defaultValue={productReview.Data.ratings}
style={{ color: '#ffa11a' }}
/>                                                                                                                            
</div> */}
                        <div className="single-pro-shop-price">
                            <p className="flex_display">
                                <span className="price_span margin-5 fs-20">Price:</span>{" "}
                                <GetProductPriceHtml productId={productId} />
                            </p>
                            {/* <div class="snap_emi_txt"></div>
                            <span class="snapmint_lowest_emi_value" Style="display:none;color:white"  data-snapmint-price={ SingleProductDetails.Data.OfferPrice=== 0  ?  SingleProductDetails.Data.Price : SingleProductDetails.Data.OfferPrice}
                            data-snapmint-merchant_id="2517" data-snapmintpage="products_page"></span> */}
                            <p className="flex_display">
                                <span className="available_span margin-5">Available:</span>
                                <CheckProductQuantity productId={productId} />
                            </p>
                            <p>
                                <span className="sku_span margin-5">SKU:</span>
                                {SingleProductDetails.Data.SKU}
                            </p>
                            {/* <p><span className = "spacing_span">Add to wishlist</span><HeartOutlined /></p> */}
                            {offers && Displayoffer ? Displayoffer : <p></p>}
                            {SingleProductDetails.Data.Barcode !== "" ? (
                                <p>
                                    <span className="ean_span margin-5">UPC/EAN:</span>
                                    {SingleProductDetails.Data.Barcode}
                                </p>
                            ) : (
                                <p></p>
                            )}
                            {SingleProductDetails.Data.Comment !== "" ? (
                                <p>{SingleProductDetails.Data.Comment}</p>
                            ) : (
                                <p></p>
                            )}
                        </div>

                        <div>
                            <Image src={logo} style={{ marginBottom: "10" + "px" }} />
                            <p>Credit Card/ Debit Card/ Net Banking/ COD Available</p>
                            <p>No Cost EMI and Standard EMI Option Available</p>
                        </div>

                        <div className="quantity">
                            <p className="quantity-p">Quantity: </p>
                            <div className="num-block skin-2">
                                {/* <QuantityButton id = {id} Quantity = {0} ItemsInStock = {SingleProductDetails.Data.Quantity}/> */}
                                <QuantityButton
                                    handleParentCounter={handleParentCounter}
                                    id={productId}
                                    Quantity={counter}
                                    ItemsInStock={SingleProductDetails.Data.Quantity}
                                />
                            </div>
                        </div>
                        {productQty > 0 ? (
                            <Row>
                                <Col md={6}>
                                    <div className="single-shop-page-btn">
                                        <Button
                                            className="fag-btn"
                                            onClick={(data) =>
                                                handlecart({
                                                    id: productId,
                                                    isLoggedIn: isLoggedIn,
                                                    customer_id: customerId,
                                                    qty: counter,
                                                    Slug: slugurl,
                                                })
                                            }
                                        >
                                            add to cart
                                        </Button>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    {SingleProductDetails.Data.PreOrderExists === "0" ? (
                                        <div className="single-shop-page-btn">
                                            <Button
                                                className="fag-btn"
                                                onClick={(data) =>
                                                    handlebuynow({
                                                        id: productId,
                                                        isLoggedIn: isLoggedIn,
                                                        customer_id: customerId,
                                                        qty: counter,
                                                    })
                                                }
                                                href={"/checkout?src=" + productId}
                                            >
                                                Buy Now
                                            </Button>
                                        </div>
                                    ) : (
                                        <div className="single-shop-page-btn">
                                            <Button
                                                className="fag-btn"
                                                onClick={(data) =>
                                                    handlebuynow({
                                                        id: productId,
                                                        isLoggedIn: isLoggedIn,
                                                        customer_id: customerId,
                                                        qty: counter,
                                                    })
                                                }
                                                href={"/checkout?src=" + productId}
                                            >
                                                Pre Order
                                            </Button>
                                        </div>
                                    )}
                                </Col>
                            </Row>
                        ) : (
                            <Row>
                                <Col md={6}>
                                    <div className="single-shop-page-btn">
                                        <Button
                                            className="fag-btn"
                                            onClick={(data) =>
                                                handleNotify({
                                                    id: productId,
                                                    isLoggedIn: isLoggedIn,
                                                    customer_id: customerId,
                                                    qty: counter,
                                                })
                                            }
                                        >
                                            {buttonText}
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                        )}

                        <div className="single-pro-shop-price">
                            <p className="flex_display product_category_list">
                                <span className="spacing_span">CATEGORIES:</span>
                                <GetProductCategoriesData productId={productId} />
                            </p>
                        </div>
                        <div className="single-pro-page-para">
                            {renderHTML(SingleProductDetails.Data.ShortDescription)}
                        </div>
                    </div>
                </Col>
            </Row>


            {/* Related Products  */}

            <Col lg={12} className="preorder-col-12">
                <div className="site-heading site-heading-related-prod site-head-mobile">
                    <h2 className="heading_animation">
                        Related <span>Products</span>
                    </h2>
                </div>
            </Col>
            <div className="carousel-container">
                <Carousel
                    slidesToShow={1}
                    arrows
                    prevArrow={<FontAwesomeIcon icon={faBackward} className="custom-prev-arrow custom-arrow" />}
                    nextArrow={<FontAwesomeIcon icon={faForward} className="custom-next-arrow custom-arrow" />}
                    dots >
                    {slides.map((slideProducts, index) => (
                        <div className="carousel-slide" key={`slide-${index}`}>
                            {slideProducts.map((item) => (
                                <div className="games-item games-item-mobile mobile" key={item.id}>
                                    <div className=" mobile" key={item.id}>
                                        <div className="game-single-item img-contain-isotope">
                                            <div className="games-thumb">
                                                <div className="games-thumb-image">
                                                    <div onClick={() => handleImageClick(item.Slug)} // Update the onClick event handler 
                                                    >
                                                        <Img src={Get_image("products", item.id, item.img_url)} alt="Game" debounce={1000} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="games-desc">
                                                <h3 className="name-up">
                                                    <div onClick={() => handleImageClick(item.Slug)}> {/* Update the onClick event handler */} {item.name} </div>
                                                </h3>
                                                <div className="game-action">
                                                    <div className="game-price">
                                                        <h4> ₹ {item.offerprice > 0 ? item.offerprice : item.price} </h4>
                                                        {item.DiscPerc ? (<p className="off">{item.DiscPerc}% Off</p>) : null}
                                                    </div>
                                                    <div className="game-buy" id="buy-now-up">
                                                        {item.PreOrder === "0" ?
                            <Card.Link  className = 'fag-btn-outline buy-now-btn' id='fag-shop-buy-btn' onClick={data => handlebuynow({id: item.id, isLoggedIn: isLoggedIn, customer_id: customerId, qty:1 })} href = {"/checkout?src="+item.id}>
                                    Buy Now
                            </Card.Link>:
                            <Card.Link  className = 'fag-btn-outline buy-now-btn' id='fag-shop-buy-btn' onClick={data => handlebuynow({id: item.id, isLoggedIn: isLoggedIn, customer_id: customerId, qty:1 })} href = {"/checkout?src="+item.id}>
                                    Pre Order
                            </Card.Link>
                            }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ))}
                </Carousel>
            </div>

            <Row>
                <Col lg={12}>
                    <div className="product-panel-list">
                        <div className="product-tab">
                            <OptionTabs
                                activeTab={1}
                                tabsData={SingleProductDetails.Data}
                                reviewData={productReview}
                                meta_info={{
                                    id: productId,
                                    isLoggedIn: isLoggedIn,
                                    customer_id: customerId,
                                    qty: counter,
                                }}
                            />
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    );

    return (
        <>
            {/* <SnapmintScript /> */}
            <Helmet>
                <title>{title}</title>‍
                <meta name="description" content={desc} />
                <meta name="keywords" content={keyword} />
            </Helmet>
            <div className="fag-product-details section_100_single_prod mt-70">
                {
                    (SingleProductDetails && productReview) &&
                        <LoadSingleProductComponent /> ?
                        <LoadSingleProductComponent /> :
                        <Spin indicator={productLoader} />
                }
            </div>
        </>
    )
}

export default ProductDetails;