import React from 'react';
// import "../../../assets/css/style.css";
// import {MDBIcon } from "mdbreact";
// import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';





function Cont_details(){
return(
    <>
  
         <div className="container">
                 
            <div className="row">
               <div className="col-lg-4">
                  <div className="single-contact-box">
                     <div className="contact-icon">
                     {/* <MDBIcon icon="map" /> */}
                     {/* <MDBIcon icon="map-marker-alt" size=""/> */}
                     </div>
                     <div className="contact-head">
                        <h4>Location</h4>
                     </div>
                     <div className="contact-text">
                        <p>Shop no. 3, Grover Mansion, 91 Mint Road, Ballard Estate, Fort, Mumbai - 400001</p>
                     </div>
                  </div>
               </div>
               <div className="col-lg-4">
                  <div className="single-contact-box">
                     <div className="contact-icon">
                     {/* <MDBIcon icon="phone" /> */}
                     </div>
                     <div className="contact-head">
                        <h4>Phones</h4>
                     </div>
                     <div className="contact-text">
                        <p>Office: 022 4924 5872</p>
                        <p>Whatsapp: 91-6769-5872</p>
                     </div>
                  </div>
               </div>
               <div className="col-lg-4">
                  <div className="single-contact-box">
                     <div className="contact-icon">
                     {/* <MDBIcon icon="envelope" /> */}
                     </div>
                     <div className="contact-head">
                        <h4>Email</h4>
                     </div>
                     <div className="contact-text">
                        <p>letsplay@mcubegames.in</p>
                     </div>
                  </div>
               </div>
            </div>
         </div>

    
    </>
);

}

export default Cont_details;