import React, { useState, useEffect } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import API_REQUEST from "../../../config/client";
import axios from "axios";
import { Alert, Select } from "antd";
import CONST_URL from "../../../config/url";

function Product_form() {
  const [productResult, getProductResult] = useState(null);
  const [posts, setPosts] = useState();
  const [genre, setGenre] = useState();
  const [selectFiles, setSelectedFiles] = useState();
  const [selectFiles1, setSelectedFiles1] = useState();
  const [formData, setFormData] = useState("");
  const [formData1, setFormData1] = useState("");
  const [checked, setChecked] = useState([]);
  const [checked1, setChecked1] = useState([]);
  const [prod, setProd] = useState(null);
  const [prod1, setProd1] = useState(null);
  var DisplaySms = null;
  var DisplayGenre = null;

  var items = [];
  var item = [];
  const {
    Option
  } = Select;

  const [data, setData] = useState({
    VariableProduct: false,
    Name: "",
    SKU: "",
    Slug: "",
    BARCODE: "",
    COMMENT: "",
    Price: "",
    OfferPrice: "0",
    ShortDescription: "",
    Description: "",
    TabTitle: "",
    TabContent: "",
    MetaTitle: "",
    MetaKeyword: "",
    MetaDescription: "",
    Quantity: "",
    IsActive: true,
    Category: checked,
    Genre: checked1,
    ReleaseDate: "",
    VideoLink: "",
    RatingAvg: "",
    HsnNo: "",
    checked: true,
    // ProductBrand: "",
    // Order: "",
    // CashOnDelivery: "",
    action: "process_product",
  });

  useEffect(() => {
    setFormData(new FormData());
    async function fetchData() {
      await API_REQUEST("fetch", { action: "getProduct", prodId: "" }).then(
        (response) => setPosts(response)
      );
    }
    fetchData();
  }, []);

  useEffect(() => {
    setFormData1(new FormData());
    async function fetchGenre() {
      await API_REQUEST("fetch", { action: "getgenree", prodId: "" }).then(
        (response) => setGenre(response)
      );
    }
    fetchGenre();
  }, []);

  useEffect(() => {
    if (posts != null) {
      posts.data.map(i => (
        items.push(<Option key={
          i.CategoryID
        } > {
            i.CategoryName
          } </Option>)))

      setProd(items);
      setPosts(null);
    }
  }, [items]);

  useEffect(() => {
    if (genre != null) {
      genre.data.map(i => (
        item.push(<Option key={
          i.ID} > {
            i.Name
          } </Option>)))

      setProd1(item);
      setGenre(null);
    }
  }, [item]);

  function handleChange2(value2) {
    setData(data => ({
      ...data,
      Category: value2
    }));
  }

  function handleChange3(value3) {
    setData(data => ({
      ...data,
      SKey: value3
    }));
  }

  function handleChange(value) {
    setData(data => ({
      ...data,
      Genre: value
    }));
  }


  function handle(e) {
    const newdata = { ...data };
    newdata[e.target.id] =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    setData(newdata);
    console.log(newdata);
  }

  function handleClick(e, editor) {
    setData({ ...data, Description: editor.getData() });
  }

  function handleClicked(e, editor) {
    setData({ ...data, ShortDescription: editor.getData() });
  }

  function handleClicker(e, editor) {
    setData({ ...data, TabContent: editor.getData() });
  }

  const fileChange = (e) => {
    let files = e.target.files;
    setSelectedFiles(files);
  };

  const fileChange1 = (e) => {
    let files = e.target.files;
    setSelectedFiles1(files);
  };

  function submit(e) {
    e.preventDefault();
    const file_data = new FormData();
    file_data.append("file", selectFiles[0]);
    for (let i = 0; i < selectFiles1.length; i++) {
      file_data.append("files[]", selectFiles1[i]);
    }
    file_data.append("request_data", JSON.stringify(data));
    async function processProductForm() {
      console.log(file_data);
      // var BASE_URL = "http://ec2-65-2-147-162.ap-south-1.compute.amazonaws.com/admin_mcube/api/Database/";
      var BASE_URL = "http://localhost/admin_mcube/api/Database/";
      var fetchData = axios;
      var fetchGenre = axios
        .post(CONST_URL + "catalogue.php", file_data)
        .then((response) => getProductResult(response.data));
    }
    processProductForm();
  }

  const selectShortlistedApplicant = (e) => {
    const checked = e.target.checked;
    if (checked) {
      setData({ ...data, IsActive: "1" });
    } else {
      setData({ ...data, IsActive: "0" });
    }
  };

  function handle2(e) {
    setData(data => ({ ...data, HsnNo: e }));
  }


  return (
    <div className="container_admin form admin_body">
      <form onSubmit={(e) => submit(e)}>
        <div className="container_admin row">
          <div className="column leftt">
            <div className="form-check">
              <input
                type="checkbox"
                name="VariableProduct"
                id="VariableProduct"
                onChange={(e) => handle(e)}
                value={data.checked}
              />
              <label>Variable Product</label>
            </div>
            <br></br>

            <div className="col-sm-5">
              <label htmlFor="Name">Product Name *</label>
              <input
                onChange={(e) => handle(e)}
                id="Name"
                value={data.Name}
                name="Name"
                className="form-control"
                type="text"
                required
              />
            </div>
            <br></br>

            <div className="col-sm-5">
              <label>Description</label>
              <CKEditor
                id="Description"
                name="Description"
                editor={ClassicEditor}
                onChange={(e, editor) => {
                  handleClick(e, editor);
                }}
              />
            </div>
            <br></br>

            <div className="col-sm-5">
              <label htmlFor="SKU">Product SKU *</label>
              <input
                required
                onChange={(e) => handle(e)}
                id="SKU"
                value={data.SKU}
                name="SKU"
                className="form-control"
                type="text"
              />
            </div>
            <br></br>

            <div className="col-sm-5">
              <label htmlFor="Slug">Product Slug </label>
              <input

                onChange={(e) => handle(e)}
                id="Slug"
                value={data.Slug}
                name="Slug"
                className="form-control"
                type="text"
              />
            </div>
            <br></br>

            <div className="col-sm-5">
              <label htmlFor="BARCODE">Product Barcode</label>
              <input
                onChange={(e) => handle(e)}
                id="BARCODE"
                value={data.BARCODE}
                name="BARCODE"
                className="form-control"
                type="text"
              />
            </div>
            <br></br>

            <div className="col-sm-5">
              <label>Hsn No</label>
              <br></br>
              <Select
                showSearch
                style={{ width: 200 }}
                placeholder=""
                optionFilterProp="children"
                onChange={handle2}
                value={data.HsnNo}
                defaultValue={data.HsnNo}
                name="HsnNo"
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                <Option value="85238090">85238090</Option>
                <Option value="95049090">95049090</Option>
                <Option value="95045000">95045000</Option>
                <Option value="998439">998439</Option> 
                <Option value="997331">997331</Option>
              </Select>
            </div>
            <br></br>

            <div className="col-sm-5">
              <label htmlFor="COMMENT">Comment</label>
              <input
                onChange={(e) => handle(e)}
                id="COMMENT"
                value={data.COMMENT}
                name="COMMENT"
                className="form-control"
                type="text"
              />
            </div>
            <br></br>

            {/* <div className="col-sm-5">
              <label htmlFor="HSN">HSN No</label>
              <input
                onChange={(e) => handle(e)}
                id="HSN"
                value={data.COMMENT}
                name="HSN"
                className="form-control"
                type="text"
              />
            </div>
            <br></br> */}

            <div className="col-sm-5">
              <label htmlFor="Price"> Price *</label>
              <input
                onChange={(e) => handle(e)}
                id="Price"
                value={data.Price}
                name="Price"
                className="form-control"
                type="number"
              />
            </div>
            <br></br>

            <div className="col-sm-5">
              <label htmlFor="OfferPrice">Offer Price</label>
              <input
                onChange={(e) => handle(e)}
                id="OfferPrice"
                value={data.OfferPrice}
                defaultValue={0}
                name="OfferPrice"
                className="form-control"
                type="number"
              />
            </div>
            <br></br>

            <div className="col-sm-5">
              <label htmlFor="Quantity">Stock Quantity</label>
              <input
                onChange={(e) => handle(e)}
                id="Quantity"
                value={data.Quantity}
                name="Quantity"
                className="form-control"
                type="number"
              />
            </div>
            <br></br>

            <div className="col-sm-5">
              <label>Short Description</label>
              <CKEditor
                id="ShortDescription"
                name="ShortDescription"
                editor={ClassicEditor}
                onChange={(e, editor) => {
                  handleClicked(e, editor);
                }}
              />
            </div>
            <br></br>
            <br></br>

            <div className="col-4">
              <label>Release Date </label>
              <input
                onChange={(e) => handle(e)}
                id="ReleaseDate"
                className="form-control"
                type="date"
                name="ReleaseDate"
                style={{ width: "200px" }}
              />
              <br></br>
            </div>

            <div className="col-sm-5">
              <label>Video Link </label>
              <input
                onChange={(e) => handle(e)}
                id="VideoLink"
                className="form-control"
                name="VideoLink"
              />
              <br></br>
            </div>

            <div className="col-sm-5">
              <label htmlFor="RatingAvg"> Rating Average</label>
              <input
                onChange={(e) => handle(e)}
                id="RatingAvg"
                value={data.RatingAvg}
                name="RatingAvg"
                className="form-control"
                type="number"
                min="1" max="5"
              />
            </div>
            <br></br>

            <h4>SEO SECTION</h4>
            <br></br>
            <div className="col-sm-5">
              <label>Meta Title</label>
              <input
                onChange={(e) => handle(e)}
                id="MetaTitle"
                value={data.MetaTitle}
                type="text"
                className="form-control"
                name="MetaTitle"
              />
              <br></br>
            </div>

            <div className="col-sm-5">
              <label>Meta Keyword</label>
              <input
                onChange={(e) => handle(e)}
                id="MetaKeyword"
                value={data.MetaKeyword}
                type="text"
                className="form-control"
                name="MetaKeyword"
              />
              <br></br>
            </div>

            <div className="col-sm-5">
              <label>Meta Description </label>
              <textarea
                onChange={(e) => handle(e)}
                id="MetaDescription"
                className="form-control"
                rows="3"
                name="MetaDescription"
              />
              <br></br>
            </div>
          </div>

          {/* LEFT COLUM ENDS HERE */}

          <div class="column rightt">
            <div className="col-sm-5">
              <label>Product Category</label>
            </div>


            <Select mode="tags" style={{ width: '100%' }} placeholder="Product Categories" onChange={handleChange2}>
              {prod}
            </Select>


            <br></br>
            <br></br>

            <div className="col-sm-5">
              <label>Product Genre</label>
            </div>


            <Select mode="tags" style={{ width: '100%' }} placeholder="Product Genres" onChange={handleChange}>
              {prod1}
            </Select>
            
            <br></br>
            <br></br>

            <div className="col-sm-5">
              <label>Search Keywords</label>
            </div>


            <Select mode="tags" style={{ width: '100%' }} placeholder="Search Keywords" onChange={handleChange3}>
              <Option> Type Search Keywords </Option>
            </Select>

            <br></br>
            <br></br>

            <div className="col-sm-5">
              <label>Product Image</label>
              <br></br>
              <input
                accept="image/png,  image/jpeg, image/.webp"
                required
                type="file"
                name="file"
                id="image_upload"
                onChange={fileChange}
              />
              <br></br> <br></br>
            </div>

            <div className="col-sm-5">
              <label>Product Gallery</label>
              <br></br>
              <input
                accept="image/png,  image/jpeg, image/.webp"
                required
                multiple
                type="file"
                name="files"
                id="image_upload"
                onChange={fileChange1}
              />
              <br></br> <br></br>
            </div>

            <div className="col-sm-5">
              <label for="scales">IsActive</label>
              <input type="checkbox" defaultChecked={true} onChange={(e) => handle(e)} value={data.IsActive} name="IsActive" id="IsActive" />
            </div>
            <br></br>

            <button type="submit" className="btn btn-primary button "
              style={{ marginRight: 1 + "em", border: "none", backgroundColor: "#4CAF50", color: "white", }} >
              Submit
            </button>
            <button type="reset" className="btn btn-primary button"
              style={{ marginRight: 1 + "em", border: "none", backgroundColor: "#FF0000", color: "white", }} >
              <i className="fas fa-arrow-left"></i>
              Cancel
            </button>
            {productResult ? (
              <Alert message={productResult.message} type="success" />
            ) : (
              <p></p>
            )}
          </div>
        </div>
      </form>
    </div>
  );
}

export default Product_form;
