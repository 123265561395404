import React, { useState, useEffect } from "react";
import API_REQUEST from "../../../config/client";
import { Row, Col, Form, Button } from "react-bootstrap";
import { Select } from "antd";
import axios from 'axios'
import CONST_URL from "../../../config/url";
import { Alert } from 'antd';
import InvoiceGenerator from "./invoice_generator";

function OrderSummary(props) {
  const [OrderSummary, fetchOrderSummary] = useState(null);
  const [orderResult, getOrderResult] = useState(null)
  const [availstatus, getAvailStatus] = useState(false)
  const [idst,setIdSt] = useState();
  const prodid = []


  const [cdata, setData] = useState({
    Status: "",
    CourierPartner: "",
    Notes: "",
    SNotes: "",
    TrackingNumber: "",
    voucherID: "",
    OrderId: props.orderId,
    action: "process_orders"
  });

  useEffect(() => {

    async function fetchData() {
      var request = { action: "fetchOrderSummary", orderid: props.orderId };
      await API_REQUEST("order_operation", request).then((response) =>
        fetchOrderSummary(response)
      );
    }
    fetchData();
  }, []);

  useEffect(() => {
    if (OrderSummary) {
        setData({
          Notes: OrderSummary.data.Notes,
          SNotes: OrderSummary.data.SNotes,
          TrackingNumber: OrderSummary.data.TrackingNumber,
          Status: OrderSummary.data.OrderStatus,
          CourierPartner: OrderSummary.data.CourierPartner,
          OrderId: props.orderId,
          voucherID: OrderSummary.data.voucherID,
          ProdDtls: OrderSummary.prodlisting,
          email: OrderSummary.data.email,
          action: "process_orders"
        })
        getAvailStatus(true)
    }
  }, [OrderSummary]);

  function submit(e) {
    e.preventDefault();
    const file_data = new FormData();
    file_data.append('request_data', JSON.stringify(cdata))
    async function processOrders() {
        console.log(file_data)
        // var BASE_URL = 'http://ec2-65-2-147-162.ap-south-1.compute.amazonaws.com/admin_mcube/api/Database/'
        var BASE_URL = 'http://localhost/admin_mcube/api/Database/'
        var fetchData = axios.post(CONST_URL + 'orders.php', file_data).then((response) => getOrderResult(response.data))

    }
    processOrders()
  }
  
  var prod_list = "";
  if (OrderSummary !== null) {
    prod_list = OrderSummary.prodlisting.map((order) => (
      <tr>
        <td>{order.ProductName}</td>
        <td class="alignright">{order.Price}</td>
        <td class="alignright">{order.QTY}</td>
        <td class="alignright">{order.total}</td>
        <td class="alignright">{order.HsnNo}</td>
      </tr>
    ));

  }

  const { Option } = Select;

  function handle1(e) {
    setData(data => ({ ...data, Status: e }));
  }

  function handle2(e) {
    setData(data => ({ ...data, CourierPartner: e }));
  }

  function handle(e) {
    const newdata = { ...cdata };
    newdata[e.target.id] = e.target.value;
    setData(newdata);
    console.log(e.target.value);
    console.log(newdata);
  }


function handle(e) {
  const newdata = { ...cdata }
  newdata[e.target.id] = e.target.value
  setData(newdata)
}

  return (
    availstatus &&
    <div className="container_admin admin_body form">
      <br></br>
      <h4>OrderId # {OrderSummary.data.OrderId}</h4>

      <p>Payment via {OrderSummary.data.payment_type}</p>
      { OrderSummary.data.voucherID !== "" ? 
      <p> Voucher ID - {OrderSummary.data.voucherID}  was applied to this Order, <mark style={{backgroundColor:'#c0ffc8'}}> After the Order Status Updated to Processing the Max Use of this Voucher will be Reduced by 1</mark></p> : <p></p>}
      

      <Row><Col>
        <b>Date created: </b>
        {OrderSummary.data.OrderDate}
      </Col>
        <Col>
          <b>Billing: </b>
          {
            OrderSummary.data.sAddress1 + ' ' + OrderSummary.data.sAddress2 + ' ' + OrderSummary.data.sPincode + ' ' + OrderSummary.data.scity + ' ' + OrderSummary.data.StateName
          }
        </Col></Row>

      <Row><Col>
        Status: 
        {OrderSummary.data.Status}
      </Col>
        <Col>
          <b>Email: </b>
          {OrderSummary.data.email}
        </Col>
      </Row>

      <Row><Col>
        <b>Customer: </b>
        {OrderSummary.data.ShipTo}
      </Col>
        <Col>
          <b>Phone: </b>
          {OrderSummary.data.bContactNumber}
        </Col>
      </Row>

      <br></br>

      <div className="content-wrap aligncenter">
        <Form onSubmit={(e) => submit(e)}>

          <Row className="mb-3">
            <Col>
              <label htmlFor="Notes">Customer Notes</label>
              <input onChange={(e) => handle(e)} id="Notes" value={cdata.Notes} name="Notes" className="form-control" type="text" placeholder="Customer Notes" />
            </Col>
            <Col>
              <label htmlFor="Notes">Supplier Notes</label>
              <input onChange={(e) => handle(e)} id="SNotes" value={cdata.SNotes} name="SNotes" className="form-control" type="text" placeholder="Supplier Notes" />
            </Col>
            <Col>
              <label htmlFor="TrackingNumber">Tracking Number</label>
              <input onChange={(e) => handle(e)} id="TrackingNumber" value={cdata.TrackingNumber} name="TrackingNumber" className="form-control" type="text" placeholder="Tracking Number" />
            </Col>
          </Row>

          <Row className="mb-3">

            <Col>
              <label htmlFor="Status">Status</label>
              <Select
                className="form-control_admin"
                showSearch
                // style={{ width: 200 }} 
                placeholder="Change Status"
                optionFilterProp="children"
                name="Status"
                onChange={handle1}
                value={cdata.Status}
                defaultValue={cdata.Status}
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                <Option value="1">Order Placed</Option>
                <Option value="2">Processing</Option>
                <Option value="3">Hold</Option>
                <Option value="4">Completed</Option>
                <Option value="5">Cancelled</Option>
                <Option value="6">Failed</Option>
                <Option value="7">Shipped</Option>
                <Option value="8">Delivered</Option>
              </Select>
            </Col>


            <Col>
              <label htmlFor="Courier Partner">Courier Partner</label>
              <Select
                className="form-control_admin"
                showSearch
                // style={{ width: 200 }}
                placeholder="Courier Partner"
                optionFilterProp="children"
                // onChange={e=> setData({CourierPartner:e.target.value, TrackingNumber: data.TrackingNumber, Status: data.Status, Notes: data.Notes })}
                onChange={handle2}
                value={cdata.CourierPartner}
                defaultValue={cdata.CourierPartner}
                name="CourierPartner"
                // onSearch={onSearch}
                // id="CourierPartner"
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                <Option value="https://www.bluedart.com/tracking">Bluedart</Option>
                <Option value="https://www.fedex.com/en-in/home.html">FedEx</Option>
                <Option value="https://www.delhivery.com/">Delhivery</Option>
                <Option value="https://www.xpressbees.com/track/">Xpressbees</Option>
                <Option value="https://ecomexpress.in/">Ecom Express</Option>
                <Option value="https://track.amazon.in/">Amazon Shipping</Option>
              </Select>
            </Col>
          </Row>
         
          <br></br>
          <button type="submit" className="btn btn-primary button" style={{ marginRight: 1 + "em", border: "none", color: "white", }} > Submit </button>
          {
                    orderResult ? <Alert message={orderResult.message} type="success" /> : <p></p>
        }
        </Form>
      </div>

      <table className="body-wrap">
        <tbody>
          <tr>
            {/* <td></td>  */}
            {/* <td className="container" width="600">
              <div className="content">
                <table
                  className="main"
                  width="100%"
                  cellpadding="0"
                  cellspacing="0"
                >
                  <tbody>
                    <tr>
                      <td class="content-wrap aligncenter">
                        <table width="100%" cellpadding="0" cellspacing="0">
                          <tbody>
                            <tr>
                              <td class="content-block">
                                <table class="invoice">
                                  <tbody>
                                    <tr>
                                      <td>
                                        <table
                                          class="invoice-items"
                                          cellpadding="0"
                                          cellspacing="0"
                                        >
                                          <thead>
                                            <tr>
                                              <td>Product</td>
                                              <td>Cost</td>
                                              <td>Qty</td>
                                              <td>Total</td>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {prod_list}
                                            <tr class="total">
                                              <td class="alignright"></td>
                                              <td class="alignright"></td>
                                              <td class="alignright">Total</td>
                                              <td class="alignright">
                                                {OrderSummary.subtotal}
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </td> */}
            <td>
              <InvoiceGenerator OrderSummary = {OrderSummary} prod_list = {prod_list}/>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default OrderSummary;
