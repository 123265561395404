


function Get_image(folder_name,temp_code,image){

    var url = "https://mcube-india.s3.ap-south-1.amazonaws.com/uploads/" +folder_name+"/"+temp_code+"/"+image;

    return url;
}

export default Get_image