import axios from 'axios'

function API_REQUEST(param, request_data) {
    //const req_data = JSON.parse(request_data)
    const headers = {'Content-Type': 'application/json'}
    // """ url servers """
        var BASE_URL = 'https://mcubegames.in:8443/mcube_ecomm/mcubefront/api/Database/'

    // """ url windows os """
    // var BASE_URL = 'http://localhost/mcube_ecomm/mcubefront/api/Database/'

    // """ url montery os """
    // var BASE_URL = 'http://localhost/~danishkhan/mcube_ecomm/mcubefront/api/Database/'

    var fetchData = axios.post(BASE_URL+param+'.php', {request_data},{headers}).then((response) => response.data)
    return fetchData
}

export default API_REQUEST;
